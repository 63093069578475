<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div>
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/image/contact.png);">
    <h1 style="color: aliceblue;">{{Content.title}}</h1>

</div>

</div>
<!-- End Page Title Area -->
<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">

        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <!-- <form #contactForm="ngForm" > -->
                        <form  #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" *ngIf="showForm == false">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name"  [(ngModel)]="object.name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                        <!-- <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="email" type="text" #email="ngModel" [(ngModel)]="object.email" class="form-control" id="email" placeholder="Your email address">
                                        <!-- <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required  name="number" type="text" #number="ngModel" [(ngModel)]="object.number" class="form-control" id="number" placeholder="Your phone number">
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" [(ngModel)]="object.message"  cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                        <!-- <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" (click)="send()" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SEND MESSAGE</button>
                                </div>
                            </div>
                        </form>
                      
                    </div><br> <br>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>Working Hours</h3>
                        <p>Tuesday - Sunday : 10:00 AM - 06:30 PM</p>
                       
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Head Office </h3>
                            <p><b style="color: #0e5e5d;">HOME & ALL,</b><br> Rohan Madhuban II, D-304, PVPIT College Rd.
                            Bavdhan Khurd, PUNE, Maharashtra 411021</p>
                            <p>Mobile: <a href="tel:+9370111694">(+91) 9370111694 </a></p>
                            <p>Costomer Care: <a href="tel:+909033515">(+91) 9090335151</a></p>

                            <p>E-mail: <a href="mailto:homeandall.marketing@gmai.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h5>Home & All, Ichalkaranji </h5>
                        <p><b style="color: #0e5e5d;">HOME & ALL, </b><br>
                            6/1110, Opp. Dr Badbade, Mahasatta Chowk, Sangli Rd., 
                            Ichalkaranji, Dist. Kolhapur, Maharashtra 416115</p>
                        <p>Mobile: <a href="tel:+8265065859">(+91) 8265065859 , 8459212789 </a></p>
                        <p>E-mail: <a href="mailto:homeandall.marketing@gmai.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Home & All, Sangli</h3>
                        <p><b style="color: #0e5e5d;">HOME & ALL,</b><br> 
                            Siddhivinayak Aradhana, Flat 08, Golden Park, Nr. Pune Bypass Rd., Kalanagar, Sangli, Dist. Sangli, Maharashtra 416416</p>
                        <p>Mobile: <a href="tel:+44457895789">(+91) 9423043403</a></p>
                        <p>E-mail: <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.5142327553795!2d73.7683256743675!3d18.50565006963147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be69e2ae0fff%3A0xf5f684a733fa5880!2sRohan%20Madhuban%202!5e0!3m2!1sen!2sin!4v1685082758643!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>