import { Component } from '@angular/core';

@Component({
  selector: 'app-our-project',
  templateUrl: './our-project.component.html',
  styleUrls: ['./our-project.component.scss']
})
export class OurProjectComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitle = [
      {
          bgImage: 'assets/img/page-title-bg.jpg',
          title: 'Our Projects'
      }
  ]
  
  singleKitchenItem = [

      {
            img: 'assets/image/kitchen-1.png'
      },
      {
            img: 'assets/image/kitchen-2.png'
      },
      {
            img: 'assets/image/kitchen-3.png'
      },
      {
            img: 'assets/image/kitchen-4.png'
      },
      {
            img: 'assets/image/kitchen-5.png'
    },
      {
            img: 'assets/image/kitchen-6.png'
    },
      {
            img: 'assets/image/kitchen-7.png'
    },
      {
            img: 'assets/image/kitchen-8.png'
    },
      {
            img: 'assets/image/kitchen-9.png'
    }
  ]
  WardrobeItem = [

      {
            img: 'assets/image/wardrobe/wd_01.png'
      },
      {
            img: 'assets/image/wardrobe/wd_02.png'
      },
      {
            img: 'assets/image/wardrobe/wd_03.png'
      },
      {
            img: 'assets/image/wardrobe/wd_04.png'
      },
      {
            img: 'assets/image/wardrobe/wd_05.png'
    },
      {
            img: 'assets/image/wardrobe/wd_06.png'
    },
      {
            img: 'assets/image/wardrobe/wd_07.png'
    },
      {
            img: 'assets/image/wardrobe/wd_08.png'
    },
      {
            img: 'assets/image/wardrobe/wd_09.png'
    }
  ]
  singlecommercialItem = [

      {
            img: 'assets/image/com-01.png'
      },
      {
            img: 'assets/image/com-02.png'
      },
      {
            img: 'assets/image/com-03.png'
      },
      {
            img: 'assets/image/com-04.png'
      },
      {
            img: 'assets/image/com-05.png'
    },
      {
            img: 'assets/image/com-06.png'
    },
      {
            img: 'assets/image/com-07.png'
    },
      {
            img: 'assets/image/com-08.png'
    },
      {
            img: 'assets/image/com-09.png'
    }
  ]
  officelItem = [

      {
            img: 'assets/image/img_01.png'
      },
      {
            img: 'assets/image/img_02.png'
      },
      {
            img: 'assets/image/img_03.png'
      },
      {
            img: 'assets/image/img_04.png'
      },
      {
            img: 'assets/image/img_05.png'
    },
      {
            img: 'assets/image/img_06.png'
    },
      {
            img: 'assets/image/img_07.png'
    },
      {
            img: 'assets/image/img_08.png'
    },
      {
            img: 'assets/image/img_09.png'
    }
  ]

  BedItem = [

      {
            img: 'assets/image/bed/bed_01.png'
      },
      {
            img: 'assets/image/bed/bed_02.png'
      },
      {
            img: 'assets/image/bed/bed_03.png'
      },
      {
            img: 'assets/image/bed/bed_04.png'
      },
      {
            img: 'assets/image/bed/bed_05.png'
       },
      {
            img: 'assets/image/bed/bed_06.png'
      },
      {
            img: 'assets/image/bed/bed_07.png'
      },
      {
            img: 'assets/image/bed/bed_08.png'
      },
      {
            img: 'assets/image/bed/bed_09.png'
    }
  ]
}
