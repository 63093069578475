<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1 style="color: aliceblue;">{{Content.title}}</h1>
    
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of Blog ;let i=index">
                <div class="single-blog-post" (click)="gotodetails(i)">
                    <div class="post-image" >
                        <a class="d-block">
                            <img  [src]="Content.blogImage" alt="image">
                        </a>
                    </div>
                    <div class="post-content"  >
                        <!-- <ul class="meta">
                            <li><i class="bx bx-purchase-tag"></i> <a routerLink="/{{Content.detailsLink}}">{{Content.tag}}</a></li>
                        </ul> -->
                        <h3><a >{{Content.blogTitle}}</a></h3>
                        <div [innerHTML]="Content.blogSubtitle"></div>

                        <!-- <p>{{Content.blogSubtitle}}</p> -->
                        <!-- <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.linkText}} <i class='bx bx-right-arrow-alt'></i></a> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="blogGrid = $event"></pagination-controls>
                </div>
            </div> -->
        </div>
        
    </div>
</section>
<p style="text-align: center;"> <b>Source:</b> Internet Resources</p>

<!-- End Blog Area -->