import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    whyChooseContent = [
        {
            //subTitle: '',
            title: 'OUR HISTORY',
            paragraph: '',
            content: [
                {
                    title: '2007',
                    paragraph: 'Started wire Manufacturing'
                    
                },
                {
                    title: '2008',
                    paragraph: 'Entered in Distribution of Kitchen Hardware'
                },
                {
                    title: '2016',
                    paragraph: 'Added World Class AutoMation in Factory'
                    
                },
                {
                    title: '2016',
                    paragraph: 'Started Modular Furniture Manufacturing'
                    
                },
                {
                    title: '2022',
                    paragraph: 'Establish Online Design & Shopping Platform'
                    
                }
            ]
        }
    ]
    number=[
        {
            img:'assets/icons/no-1.jpg'
        }
    ]
    whyChooseImage = [
        {
            img: 'assets/img/why-choose-us.jpg'
        }
    ]

}