import { Component } from '@angular/core';

@Component({
  selector: 'app-gallery-page-two',
  templateUrl: './gallery-page-two.component.html',
  styleUrls: ['./gallery-page-two.component.scss']
})
export class GalleryPageTwoComponent {

}
