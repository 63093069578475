
<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section  >
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Modular cabinets that come in a range of sizes and configurations to fit any kitchen layout</li>
                    <li>Durable materials like wood, MDF, or particle board for the cabinets</li>
                    <li>A range of finish options like laminates, acrylics, or veneers to give a customized look to the kitchen</li>
                    <li>Modular countertop options like granite, marble, or quartz</li>
                    <li>Modular storage solutions like drawers, pull-outs, and shelves</li>
                    <li>Pre-fitted sink, faucet, and other fixtures for ease of installation</li>
                </ol>
            </div>
           
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Cost-effective compared to a custom-built kitchen</li>
                    <li>Time-saving, as the pre-designed components can be easily assembled and installed</li>  
                    <li>Easy to maintain and repair, as the modular components can be replaced or repaired as needed</li>
                    <li>Customizable to fit any kitchen layout and design preferences</li>
                    <li>Provides ample storage space and countertop area for efficient kitchen use</li>
                  
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The cost of a standard modular kitchen can vary depending on the size, materials used, and the brand. On average, a standard modular kitchen can cost anywhere between INR 50,000 to INR 2,50,000 or more, excluding installation and additional accessories. It is important to research and compare different Custmization options.</p>
    </div>
</section>
<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>