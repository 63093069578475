import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { WebService } from 'src/app/web.service';


declare function magnify(img,num)
declare function remove()
@Component({
    selector: 'app-shop-left-sidebar-page-one',
    templateUrl: './shop-left-sidebar-page-one.component.html',
    styleUrls: ['./shop-left-sidebar-page-one.component.scss']
})
export class ShopLeftSidebarPageOneComponent implements OnInit {
    category: any;
    selectedimgsize = 0;
    collapsFlag=0
    obj1: {};
    subcategory: any;
    id: any;
    cart: any;
    offset= 0;
    cat: any;
    product:any=[];
    selectedSubcat=[]
    selectedimg=""
    iId: any;
    productDetail:any;
    list=[];
    scategory:any=[]
    sizeFlag=false
    sizeArray=[]
    sizePrice=0;
    sizeImg:any
    setProduct:any
    pOfferPiceSize:any
    config={
        "searchk":'',
        "sort":""
    }
    loadFlag=true
    visible=false
    
    flag=false
    constructor( public web:WebService,public activeRoute:ActivatedRoute, public router:Router) {
        this.sizeFlag=false
        this.collapsFlag=0
       // this.size()
        console.log(this.setProduct)
        this.router.routeReuseStrategy.shouldReuseRoute = function(){
            return false;
        };
        this.id=activeRoute.snapshot.paramMap.get('id');
        console.log(this.id)
        if(this.id>1)
            this.flag=false
        else
            this.flag=true
        var obj=localStorage.getItem("cart");
      this.sizePrice=0
        console.log(obj)

        if(!obj)
        {
            this.list=[];
        }
        else
         this.list=JSON.parse(obj);
        
         
       
     }

     getrange(x){
        // console.log(x)
        return JSON.parse(x.ssize)[0].pOffer_prize +"-"+JSON.parse(x.ssize)[JSON.parse(x.ssize).length-1].pOffer_prize;
     }
     ionViewDidEnter(){
        this.id=this.activeRoute.snapshot.paramMap.get('id');
        console.log(this.id)
        var obj=localStorage.getItem("cart");
      
        console.log(obj)

        if(!obj)
        {
            this.list=[];
        }
        else
         this.list=JSON.parse(obj);
        
        console.log(this.list)
        this.shopLeftSidebar=1
        this.web.getData({},"?action=getsub_category").then(result=>{
            var res:any=result; 
           this.category=res.records
           console.log(this.category);
        });
        this.web.getData({},"?action=getsub_categorybycatid_get&id="+this.id).then(result=>{
            var res:any=result; 
           this.subcategory=res.records
         //  this.productDetail=this.subcategory[0];
           console.log(this.subcategory);
        });
     }
     collaps()
     {
        var coll:any = document.getElementsByClassName("collapsible");
        // console.log(JSON.parse(JSON.stringify(coll[0])))
        var i;
        for (i = 0; i < coll.length; i++) {
            // console.log(this.collapsFlag)
            coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            // console.log(content)
            if (content.style.maxHeight){
            content.style.maxHeight = null;
            } else {
            content.style.maxHeight = content.scrollHeight + "px";
            } 
        });
        }
     }

     copyCode(k)
     {
  
    navigator.clipboard.writeText("homeandall.in/#/Product/"+k);
    
    // Alert the copied text
    alert("Copied Product URL ");

     }
     setproduct(k){
        this.setProduct=JSON.parse(JSON.stringify(k))
        this.web.activateflag=true;
         console.log(k);
         this.web.product=k;
         this.productDetail=JSON.parse(JSON.stringify(k));
         console.log(this.productDetail)
         this.selectedimg=k.pPicture.split('~')[0].trim()
         console.log(k.ssize)
         this.size()
         remove()
        //  magnify("myimage", 3);

     }
     size()
     {
        console.log(this.sizeFlag)
        if(this.setProduct.ssize==null || this.setProduct.size=="null")
        {
          
           this.sizeFlag=false

        }
        else
        {
           this.sizeFlag=true
           this.sizeArray=JSON.parse(this.setProduct.ssize)
           console.log(this.sizeArray)
           if(this.sizeArray==null)
               {
                   this.web.product.ssize==null
                   this.sizeFlag=false
               }
           else
                {
                  console.log( this.sizeArray.length)
                   this.pOfferPiceSize=this.sizeArray[0].pOffer_prize;
                   this.sizeImg=this.sizeArray[0].psizePicture
                   console.log(this.pOfferPiceSize,this.sizeImg)
                }
}
     }
     setimg(k)
     {
        this.selectedimg=k;
        remove()
        // magnify("myimage", 3);

     }
     imgSize(a)
     {
        console.log(a)
        console.log(this.sizeArray[a])
        this.selectedimgsize=1
        // this.selectedimg=this.sizeArray[a].psizePicture
        // this.productDetail.pPicture=this.selectedimg
        this.productDetail.pPicture=this.sizeArray[a].psizePicture
        this.setimg(this.productDetail.pPicture.split('~')[0])
        // this.productDetail.pPicture=this.selectedimg
        // this.setproduct(this.productDetail)

       
     }
     ngOnInit()
     {
        console.log("as")
        this.id=this.activeRoute.snapshot.paramMap.get('id');

        var obj=localStorage.getItem("cart");
      
        console.log(obj)

        if(!obj)
        {
            this.list=[];
        }
        else
         this.list=JSON.parse(obj);
        
        console.log(this.list)
        
        // this.web.getData({},"?action=getsub_category").then(result=>{
        //     var res:any=result; 
        //    this.category=res.records
        //    console.log(this.category);
        // });
        this.shopLeftSidebar=1
        if(this.flag==false)
        {   

            this.web.getData({},"?action=getsub_categorybycatid_get&id="+this.id).then(result=>{
                var res:any=result; 
               this.subcategory=res.records
               console.log(this.subcategory);
    
               if(this.subcategory.length>0)
               {
               this.iId=this.subcategory[0].iId
               this.selectedSubcat=this.subcategory[0].sName
    
               console.log(this.iId)
       
               this.web.getData({},"?action=getproductbycatid&iId="+this.iId+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                   var res:any=result; 
                  this.product=res.records
                  console.log(this.product);
                  this.productDetail=this.product[0];
                 
                  this.setProduct=this.productDetail
                  console.log(this.productDetail)
                  //this.size();
    
               });
               
            }
            
            });
        }
        else
        {  
                this.loadFlag=true
                this.web.getData({},"?action=getproductbysearchkey"+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                this.loadFlag=false
                var res:any=result; 
               this.product=res.records
               console.log(this.product);
               this.productDetail=this.product[0];
              
               this.setProduct=this.productDetail
               //this.size();
 
            });
        }
       

     }
    
     goto(i)
    {
       
        console.log(i)
        this.iId=this.subcategory[i].iId
        console.log(this.iId)
        this.selectedSubcat=this.subcategory[i].sName
        this.shopLeftSidebar=1;
        this.web.getData({},"?action=getproductbycatid&iId="+this.iId+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
            var res:any=result; 
           this.product=res.records
           console.log(this.product);
           this.productDetail=this.product[0];

        });
        var id=document.getElementById("cbuttion").click();

        //this.collaps()
    } 
    onclick(){
        this.shopLeftSidebar=1;
        if(this.flag==false)
        {
            this.loadFlag=true

            this.web.getData({},"?action=getproductbycatid&iId="+this.iId+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                var res:any=result; 
               this.product=res.records
               console.log(this.product);
               this.productDetail=this.product[0];
               console.log(this.config)
               this.loadFlag=false

    
            });
        }
        else
        {            
            this.loadFlag=true
            this.web.getData({},"?action=getproductbysearchkey"+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                var res:any=result; 
               this.product=res.records
               console.log(this.product);
               this.productDetail=this.product[0];
               console.log(this.config)
               this.loadFlag=false

    
            });
        }
        
    }
    // addSize(i)
    // {
    //     console.log(this.sizeArray[i])
    //     console.log(i)
    //     this.sizePrice=this.sizeArray[i].pOffer_prize
    // }
    addcart(a){
        if(this.sizeFlag==false)
        {
            this.list.push(a)
            console.log(a)
            localStorage.setItem('cart',JSON.stringify(this.list))
            console.log(this.list)

        }
        else
        {
            console.log(this.sizePrice)
            a.pOffer_prize=this.sizeArray[this.sizePrice].pOffer_prize
            a.pPicture=this.sizeArray[this.sizePrice].psizePicture
            a.size=this.sizeArray[this.sizePrice].ssize
            this.list.push(a)
            console.log(a)
            console.log(this.list)

            localStorage.setItem('cart',JSON.stringify(this.list))
            console.log(this.cart)
        }
        alert('Product added successfully')

      }    
    // ngOnInit(): void {
    //     this.resetOption = [this.options[0]];
    // }
     
    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Shop Left Sidebar'
           
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/products/products4.jpg',
            title: 'Ergonomic Desk Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
            
        },
        {
            mainImg: 'assets/img/products/products5.jpg',
            title: 'Office Desk Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products9.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products10.jpg',
            title: 'Wood Patio Chair',
            newPrice: '$226.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products11.jpg',
            title: 'Brown Vinyl Padded',
            newPrice: '$107.99',
            oldPrice: '$140.99',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products12.jpg',
            title: 'Antique Walnut',
            newPrice: '$98.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            buttonIcon: 'bx bx-rupees',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            buttonIcon: 'bx bx-rupees',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            buttonIcon: 'bx bx-rupees',
            detailsLink: 'simple-product'
        }
    ]

    // For Pagination
    shopLeftSidebar: number = 1;

    // Category Select
   // sort: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    sort=""
    configs = {
        displayKey: "name",
        search: true
    };
    // options = [
    //     {
    //         name: "Price: low to high",
    //     },
    //     {
    //         name: "Price: high to low",
    //     }
    // ];
    onChange(){
        this.shopLeftSidebar=1;
        if(this.flag==false)
        {
            this.web.getData({},"?action=getproductbycatid&iId="+this.iId+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                var res:any=result; 
               this.product=res.records
               console.log(this.product);
               this.productDetail=this.product[0];
              
            });
        }
        else
        {
            this.web.getData({},"?action=getproductbysearchkey"+"&offset="+this.offset+"&searchk="+this.config.searchk+"&sort="+this.config.sort).then(result=>{
                var res:any=result; 
               this.product=res.records
               console.log(this.product);
               this.productDetail=this.product[0];
              
            });
        }
       
    }
   
   
    searchChange($event) {
        console.log($event);
        

       
    }
    reset() {
       // this.resetOption = [];
    }

}