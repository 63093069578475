<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Minimalist design: International modular kitchens are designed with clean lines, minimal handles, and sleek finishes. They are perfect for those who want a minimalist and modern look in their kitchen.</li>
                    <li>High-quality materials: The materials used in international modular kitchens are of high quality. They are usually made of materials like MDF, particleboard, and high-density fibreboard. The hardware used in these kitchens is also of high quality, making them durable and long-lasting.</li>
                    <li>Space-saving solutions: International modular kitchens are designed to make the most of the available space. They feature smart storage solutions, such as pull-out drawers, baskets, and cabinets, that maximize storage space.</li>
                    <li>Customizable: International modular kitchens are highly customizable. They can be designed to fit any space and can be customized to suit individual needs and preferences.</li>
                </ol>
            </div>
           
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Efficient use of space: With their space-saving solutions, international modular kitchens make the most of the available space, making it easier to organize and access kitchen items.</li>
                    <li>Modern and stylish: The sleek and modern design of international modular kitchens adds a touch of style and sophistication to any home.</li>
                    <li>Durable and long-lasting: The high-quality materials used in international modular kitchens ensure that they are durable and long-lasting, making them a smart investment for any home.</li>
                    <li>Customizable: International modular kitchens can be customized to suit individual needs and preferences, making them a great option for those who want a kitchen that is tailored to their needs.</li>
                  
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The pricing for international modular kitchens can vary widely depending on the size, materials, and features chosen. On average, an international modular kitchen can cost anywhere from INR 1,50,000 to INR 10,00,000 or more.</p>
    </div>
</section>
<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>