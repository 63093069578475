<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/icons/cart-1.jpg); margin-top: 5%;">
    <h1 style="color: #fff;">{{Content.title}}</h1>
    
</div>
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container" style="width: 80%; margin: auto;">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col" style="width: 25%;">Name</th>
                            <th scope="col" style="width: 20%;">Quantity</th>
                            <th scope="col" style="width: 20%;">Unit Price </th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let x of list;let i=index">
                            <td class="product-thumbnail">
                                <a >
                                    <img src="https://homeandall.in/api/upload/{{x.pPicture.split('~')[0].trim()}}.png" alt="image" >
                                </a>
                            </td>

                            <td class="product-name">
                                <a >{{x.pName}}<br>
                                    Dispatch :- {{x.iDeliverydays}} 
                                </a>
                            </td>

                           

                            <td class="product-quantity" >
                                <div class="input" >
                                    <span class="minus-btn"><i class='bx bx-minus' (click)="minusqty(i)"></i></span>
                                    <input type="text"  value="{{x.qty}}" style="width: 30%; text-align: center;" (change)="onChange($event,i)">
                                    <span class="plus-btn"><i class='bx bx-plus' (click)="plusqty(i)"></i></span>
                                   
                                </div>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount"><img src="assets/icons/rupee.png" style="height: 10px;">{{x.pOffer_prize}}/-</span>
                            </td>
                            <!-- <td class="product-quantity">
                                <div class="input">
                                    <span class="minus-btn"><i class='bx bx-minus' (click)="minusqty(i)"></i></span>
                                    <input class="text" value="{{x.qty}}" >
                                    <span class="plus-btn"><i class='bx bx-plus' (click)="plusqty(i)"></i></span>
                                </div>
                            </td> -->

                            <td class="product-subtotal">
                                <span class="subtotal-amount"><img src="assets/icons/rupee.png" style="height: 10px;">{{x.pOffer_prize*x.qty | number:'1.2'}}/-</span>
                                <a (click)="delete(i)" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-12 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">APPLY COUPON</button>
                        </div>
                    </div>

                 
                </div>
            </div> -->

            <div class="cart-totals">
                <ul>
                    <li>Subtotal <span><img src="assets/icons/rupee.png" style="height: 10px;">{{subtotal| number:'1.2'}}/-</span></li>
                    <!-- <li>Shipping <span><img src="assets/icons/rupee.png" style="height: 10px;">{{shipping}}/-</span></li> -->
                    <li>Total <span><img src="assets/icons/rupee.png" style="height: 10px;">{{subtotal+shipping| number:'1.2'}}/-</span></li>
                </ul>
                <a routerLink="/checkout" class="default-btn" (click)="addOrder()"><i class='bx bx-paper-plane'></i> PROCEED TO CHECKOUT</a>
            </div>
        </form>
    </div>
</section>
<!-- End Cart Area -->