import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-upcoming-products',
    templateUrl: './upcoming-products.component.html',
    styleUrls: ['./upcoming-products.component.scss']
})
export class UpcomingProductsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Our Home Solutions ",
            // paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/image/kitchen-03.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            detailsLink: '/gallery-page'
        },
        {
            mainImg: 'assets/image/kitchen-01.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            sale: '',
            detailsLink: ''
        },
        {
            mainImg: 'assets/image/kitchen-02.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            detailsLink: ''
        },
        {
            mainImg: 'assets/image/kitchen-04.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            sale: '',
            detailsLink: ''
        }
    ]

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}