import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
    category: any;
    subcategory: any;
    id: any;
    qty=0;
    constructor(public web:WebService,public router:Router) {
        this.web.getData({},"?action=getcategory").then(result=>{
            var res:any=result; 
           this.category=res.records
           console.log(this.category);
        });
        
     }

    ngOnInit(): void {
    }
    

}