import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss']
})
export class FacilityStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    facilityContent = [
        {
            icon: 'assets/icons/gift.jpg',
            title: 'Loyalty',
            paragraph: 'Quality raw material'
        },
        {
            icon: 'assets/icons/rocket.jpg',
            title: 'Efficiency',
            paragraph: 'Efficient Design & Execution'
        },
        {
            icon: 'assets/icons/history.jpg',
            title: 'Reliability',
            paragraph: 'Collaboration with Topmost & Reliable Brands'
        },
        {
            icon: 'assets/icons/diamond.jpg',
            title: 'Commitment',
            paragraph: 'On Time Dilivery'
        }
    ]
    facilityImage = [
        {
            img: 'assets/images/item-4.jpg'
        }
    ]

}