
<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ul>
                    <li>Furniture for educational institutions, healthcare facilities, government agencies, and other public or private organizations, including desks, chairs, tables, lockers, shelving, lab equipment, etc. that are durable, ergonomic, and compliant with safety and regulatory standards.</li>
                </ul>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ul>
                    <li>Institutional furniture: can facilitate learning, healing, and safety of students, patients, and staff by offering appropriate and accessible furniture that accommodates their physical and cognitive abilities and supports their activities. Can also reduce maintenance and replacement costs by using sturdy and easy-to-clean materials.</li>
                </ul>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>Institutional furniture: can also vary widely in pricing depending on the type, size, and function of the furniture. For example, a student desk can cost around Rs. 2,000-4,000, while a hospital bed can cost over Rs. 50,000. A small classroom or clinic may require an investment of Rs. 50,000-1 lakh, while a large campus or hospital may require an investment of several crores</p>
    </div>
</section>
<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>