import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-highlighter',
  templateUrl: './highlighter.component.html',
  styleUrls: ['./highlighter.component.scss']
})
export class HighlighterComponent {
  constructor() { }

  ngOnInit(): void {
  }

  roomsContent = [
      {
          bgImage: 'assets/image/h-bg.png',
          title: 'Highlighter Material',
          paragraph: 'International modular kitchens are known for their sleek and modern designs. They usually feature glossy finishes, minimalist handles, and high-quality hardware. Some of the common features of international modular kitchens include:',
          list: [
              {
                  title: 'Opt for light colours'
              },
              {
                  title: 'Think carefully about flooring'
              },
              {
                  title: 'Add a mirror'
              },
              {
                  title: 'Wall features'
              },
              {
                  title: 'Keep the space cosy'
              }
          ],
          buttonIcon: 'bx bx-shopping-bag',
          buttonText: 'CONTACT US',
          buttonLink: 'tel: 9090 33 5151'
      }
  ]

  singleProductsBox = [
      {
          mainImg: 'assets/image/profile-1.png',
          title: 'Rivet Farr Lotus',
          newPrice: '$150.00',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/profile-02.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/profile-03.png',
        title: 'Mesh Computer Desk',
          newPrice: '$223.99',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/profile-04.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      }
  ]

  productsSlidesOptions: OwlOptions = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='bx bx-left-arrow-alt'></i>",
    "<i class='bx bx-right-arrow-alt'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    768: {
      items: 2
    },
    992: {
      items: 3
    },
    1200: {
      items: 3
    }
  }
  }
}
