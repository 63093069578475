<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/icons/components.jpg);">
    <h1>Products</h1>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<div class="row align-items-center" style="margin: 2%;">
    <div class="col-lg-6 col-md-6">
        <div style="margin-bottom: 25px;">
        <span  style="margin-bottom: 10px;padding: 10px;" *ngFor="let k of productDetail.pPicture.split('~')">
            <img (click)="setimg(k)" onerror="this.onerror=null;this.src='/assets/icons/noimg.png';" src="https://homeandall.in/api/upload/{{k.trim()}}.png"  alt="image" style="height: 50px; border: thin solid gray;">
        </span>
        </div>
        <div >
            <div class="products-image ">
                 <pinch-zoom double-tap="true" wheel="true" wheelZoomFactor="0.5" 
                    listeners="auto"  overflow="hidden" style="background-color: #f2f4f5 !important;">
                    <img onerror="this.onerror=null;this.src='/assets/icons/noimg.png';" id="myimage" src="https://homeandall.in/api/upload/{{selectedimg.trim()}}.png" alt="image">
                </pinch-zoom>
            </div>
        </div>
   

    </div>
    <div class="col-lg-6 col-md-6">
        <div class="products-content">
            <h3 style="overflow-wrap: anywhere;">{{productDetail.pName}}</h3>
            <small>{{selectedSubcat}}</small>
            <div class="price" *ngIf="sizeFlag==false">
                <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;"> {{productDetail.pOffer_prize}}/-</span>&nbsp;
                <span class="old-price" *ngtail.pMRP>{{productDetail.pMRP}}</span>&nbsp;&nbsp;
                <span> per {{productDetail.iUnit}}({{productDetail.setof}})</span>
            </div>
            <div *ngIf="sizeFlag==true">
                <table>
                    <tr >
                        <th>Size</th>
                        <th>MRP</th>
                        <th>Offer-Price</th>
                    </tr>
                    <tr *ngFor="let x of sizeArray,let i=index" >
                        <td>{{x.ssize}}</td>
                        <td><img src="assets/icons/rupee.png" style="height: 10px;"> {{x.pMRP}}/-</td>
                        <td><img src="assets/icons/rupee.png" style="height: 10px;">{{x.pOffer_prize}}/-</td>
                    </tr>
                    
                </table>
            </div>
            <label><b>Description</b></label><br>
            <span>{{productDetail.pDiscription}}</span><br>
            <span><b> Dispatch:</b></span> {{productDetail.iDeliverydays}} <br>
            *After payment received in our Bank Account.

            <label> <b>Specification:</b></label>
            <p> {{productDetail.pProduct_specification}}</p>
           
            <ul class="products-info">
               
                <label ><b> Notes:</b></label>
                <li><span>{{productDetail.pProduct_feature}}</span></li>
                
            </ul>
            <div class="products-add-to-cart">
                <div class="row">
                    <div class="col-lg-6 col-md-3 col-sm-2 col-xs-2">
                        <div class="input-counter" *ngIf="sizeFlag==true">
                            <select [(ngModel)]="sizePrice" style="padding: 15px 15px 12px 10px;
                            color: white;
                            background-color: var(--mainColor);
                            font-weight: 600;
                            border: none;" (change)="imgSize(sizePrice)">
                                <option *ngFor="let x of sizeArray,let i=index" value="{{i}}">
                                    {{x.ssize}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-3 col-sm-2 col-xs-2" *ngIf="sizeFlag==true">
                        <button (click)="addcart(productDetail)" data-bs-dismiss="modal" type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="sizeFlag==false">
                        <button (click)="addcart(productDetail)" data-bs-dismiss="modal" type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

