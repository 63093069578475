import { Component, OnInit } from '@angular/core';
//mport { parse } from 'path';
import { WebService } from 'src/app/web.service';
import { __values } from 'tslib';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
list:any=[];
pOffer_prize: any;
quantity
total=0;
total_amt0:any;
total_amt1:any;
totalamt:any="0"

subtotal=0;
shipping=0
multi=0;

    cart: any;
    qty: any;
    checkout: any;
    object ={
     "sProductid":"",
     "sQty":"",
     "sRate":"",
     "sAmount":"",
     "total_amount":"",
    }

    constructor(public web:WebService) { 
        
        var obj=localStorage.getItem("cart");
      
        console.log(obj)

        if(!obj)
        {
            this.list=[];   
        }
        else
         this.list=JSON.parse(obj);
         console.log(this.list)
         for(let i=0;i<this.list.length;i++)
         {
            this.subtotal=this.subtotal+(parseFloat(this.list[i].pOffer_prize))*(this.list[i].qty)
            console.log(this.list[i].pOffer_prize)
            console.log(this.total)

         }

        console.log(this.list)

        this.web.getData({},"?action=getcart").then(result=>{
            var res:any=result; 
           this.cart=res.records
           console.log(this.cart);
        });
           
       
    }
    plusqty(i)
    {
       
        console.log(i)
        console.log(this.list)
        console.log(this.list[i].qty)

        this.list[i].qty=parseInt(this.list[i].qty)+1;  
        localStorage.setItem('cart',JSON.stringify(this.list));
        console.log(this.list[i].qty)
        console.log(this.list)
        this.subtotal=this.subtotal+(parseFloat(this.list[i].pOffer_prize))
        console.log(this.subtotal)
    }
    minusqty(i){
        
        console.log(this.list[i].qty)
        
        if(this.list[i].qty==1){
            console.log(i)
            this.delete(i)
        }
        else{
            console.log(i)
            this.list[i].qty=parseInt(this.list[i].qty)-1;
            this.subtotal=this.subtotal-(parseFloat(this.list[i].pOffer_prize))
            localStorage.setItem('cart',JSON.stringify(this.list));
           console.log(this.subtotal)
            
        }
       
        console.log(this.list)
    }
    checkproduct(k){

        return 0;
    }
    delete(i){
       
       // this.list[i].qty=parseInt(this.list[i].qty)-1;
       console.log(this.subtotal)

        this.subtotal=this.subtotal-(parseFloat(this.list[i].pOffer_prize)*this.list[i].qty)
        this.list.splice(i,1)

        localStorage.setItem('cart',JSON.stringify(this.list));
        console.log(this.subtotal)
       
        
    }
    ngOnInit(): void {
    }
    onChange(event: Event,i:any): void {
        this.subtotal=0
        const inputElement = event.target as HTMLInputElement;
        console.log(inputElement.value,i);
        console.log(i)
        console.log(this.subtotal)

        console.log(this.list)
        this.list[i].qty=inputElement.value
        // x.pOffer_prize*x.qty
        this.list.forEach(element => {
            this.subtotal=this.subtotal+(element.pOffer_prize*element.qty)
        });
        
            localStorage.setItem('cart',JSON.stringify(this.list));
           console.log(this.subtotal)
      }
    addOrder()
    {
        console.log(this.object)
        this.web.postData(this.object,"?action=addorder").then(result=>{
            var res:any=result; 
           this.checkout=res.records
           console.log(this.checkout);
        });
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Cart'
        }
    ]

}