<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}}); ">
   
   
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img [src]="BlogDetail.blogImage" alt="image">
                    </div>
                    <div [innerHTML]="BlogDetail.blogDetails">
                        <!-- <p>{{Content.blogDetails}}</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <hr>
                <aside class="widget-area">
                    <div class="widget widget_fiwan_posts_thumb">
                        <h3 class="widget-title"> Blogs</h3>
                        <hr>
                        

                        <article class="item" *ngFor="let Content of Blogs">
                            <a routerLink="/blog-details" class="thumb"><img [src]="Content.blogImage" alt=""></a>
                            <div class="info">
                                <!-- <h4 class="title usmall"><a routerLink="/blog-details"></a></h4> -->
                                <ul class="meta">
                                    <li>{{Content.blogTitle}}</li>
                                </ul>
                            </div>
                        </article>

                        <!-- <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">How Much Does Professional Web Design Cost</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 24, 2020</li>
                                    <li><i class='bx bx-time'></i> 3 min</li>
                                </ul>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">5 Benefits of SEO Services for Shopify Stores</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 23, 2020</li>
                                    <li><i class='bx bx-time'></i> 2 min</li>
                                </ul>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">The 4 Ingredients to Digital Marketing Success</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 22, 2020</li>
                                    <li><i class='bx bx-time'></i> 5 min</li>
                                </ul>
                            </div>
                        </article> -->
                    </div>

                    
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->