<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1 style="color: aliceblue;  margin-right: 70%;">{{Content.title}}</h1>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100 pb-70">
    <div class="container">

        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <!-- <form #contactForm="ngForm" > -->
                        
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" >
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="fname"  [(ngModel)]="object1.fname" type="text" #fname="ngModel" class="form-control" id="name" placeholder="Full name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="mobile" type="text" #mobile="ngModel" [(ngModel)]="object1.mobile" class="form-control" id="email" placeholder="Your Mobile">
                                        <!-- <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required  name="altnumber" type="text" #altnumber="ngModel" [(ngModel)]="object1.altnumber" class="form-control" id="number" placeholder="Alternate number">
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required  name="email_id" type="text" #email_id="ngModel" [(ngModel)]="object1.email_id" class="form-control" id="number" placeholder="E-mail Address">
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                   <div class="form-group mb-3">
                                    <!-- <label for="">Select State</label> -->
                                        <select required  name="state" type="text" #state="ngModel" [(ngModel)]="object1.state" class="form-control" id="number" placeholder="Select State">
                                            <option value="Select State"  >Select State</option>
                                            <option value="Maharashtra" selected>Maharashtra</option>
                                           
                                        </select>
                                    </div>
                                    <!-- <div class="form-group mb-3">
                                        <input required  name="number" type="text" #number="ngModel" [(ngModel)]="object.number" class="form-control" id="number" placeholder="Select State"> -->
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    <!-- </div> -->
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required  name="city" type="text" #city="ngModel" [(ngModel)]="object1.city" class="form-control" id="number" placeholder="City Name">
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                         <select required  name="discussion" type="text" #discussion="ngModel" [(ngModel)]="object1.discussion" class="form-control" id="number" >
                                             <option value="Preffered time for discussion" selected>Preffered time for discussion</option>
                                             <option value="Morning">Morning</option>
                                             <option value="Afternoon">Afternoon</option>
                                             <option value="Evening">Evening</option>
                                             
                                         </select>
                                     </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required  name="Location" type="text" #Location="ngModel" [(ngModel)]="object1.Location" class="form-control" id="number" placeholder="Location Preference">
                                        <!-- <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <p>Do you own any franchisee</p>
                                        <input type="radio" id="Choice1" name="franchisee" value="email" />&nbsp;
                                        <label for="Choice1">Yes</label>&nbsp;&nbsp;
                                        <input type="radio" id="Choice2" name="franchisee" value="phone" />&nbsp;
                                        <label for="Choice2">No</label>&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <p>Do you own a retail space</p>
                                        <input type="radio" id="Choice3" name="retail" value="email" />&nbsp;
                                        <label for="Choice3">Yes</label>&nbsp;&nbsp;
                                        <input type="radio" id="Choice4" name="retail" value="phone" />&nbsp;
                                        <label for="Choice4">No</label>&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                         <select required  name="Property" type="text" #Property="ngModel" [(ngModel)]="object1.Property" class="form-control" id="number" >
                                             <option value="Property Availability" selected>Property Availability</option>
                                             <option value=""></option>
                                             <option value=""></option>
                                             <option value=""></option>
                                             
                                         </select>
                                     </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                         <select required  name="Area" type="text" #Area="ngModel" [(ngModel)]="object1.Area" class="form-control" id="number" >
                                             <option value="Area Of Property Availability" selected>Area Of Property Availability</option>
                                             <option value=""></option>
                                             <option value=""></option>
                                             <option value=""></option>
                                             
                                         </select>
                                     </div>
                                 </div>
                                <!-- <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" [(ngModel)]="object.message"  cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea> -->
                                        <!-- <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div> -->
                                    <!-- </div>
                                </div> -->
                                <div class="col-lg-12 col-md-12" style="text-align: center;">
                                    <button type="submit" (click)="addEnquiry()" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SEND ENQUIRY</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <!-- <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Head Office </h3>
                            <p><b>Home & All,</b> Rohan Madhuban II, D-304, PVPIT College Rd.
                            Bavdhan Khurd, PUNE, Maharashtra 411021</p>
                            <p>Mobile: <a href="tel:+44457895789">(+91) 9090 33 5151</a></p>
                            <p>E-mail: <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Head Office </h3> -- >
                            <p><b> Franchisee 1 - Home & All, </b>
                                6/1110, Opp. Dr Badbade, Mahasatta Chowk, Sangli Rd., 
                                Ichalkaranji, Dist. Kolhapur, Maharashtra 416115</p>
                            <p>Mobile: <a href="tel:+44457895789">(+91) 9090335151</a></p>
                            <p>E-mail: <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                         <h3>Head Office </h3> -- >
                            <p><b>Franchisee 2 - Home & All,</b> 
                                Siddhivinayak Aradhana, Flat 08, Golden Park, Nr. Pune Bypass Rd., Kalanagar, Sangli, Dist. Sangli, Maharashtra 416416</p>
                            <p>Mobile: <a href="tel:+44457895789">(+91) 9423043403</a></p>
                            <p>E-mail: <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com</a></p>
                    </div>
                    
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>Working Hours</h3>
                        <p>Tuesday - Sunday : 10:00 AM - 06:30 PM</p>
                       
                    </div> -->
                    <div>
                        <img src="assets/image/Franchise.jpg" style="height: 74vh;"> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        
</section>
<!-- End Contact Area -->
