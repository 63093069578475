import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
Blogs:any=[]
BlogDetail:any={
    'detailsLink':"",
    "blogTitle":'',
    "blogSubtitle":"",
    "blogImage":""
}
i:any;
    constructor(public web:WebService, public activeRoute:ActivatedRoute) { 
        this.i=activeRoute.snapshot.paramMap.get('i');

        console.log(this.i)
        this.Blogs=this.web.object
        console.log(this.Blogs)
        this.BlogDetail=this.Blogs[this.i]
        console.log(this.BlogDetail)
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/image/blog-2.png',
            title: 'Spotlight on the Natuzzi Editions Furniture Collection'
        }
    ]

}