import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerItem = [
        {
            img:'assets/images/logo.png',
            subTitle: 'NEW GENERATION WORKSTATIONS BY HOME AND ALL',
            title: '',
            discription:'Designer’s First choice',
            paragraph: '<p>We provide convenient interior solutions for our clients who are looking for a peaceful & specious environment to live in.we are open to working together with many stakeholders to provide other interior services as well.</p> <br>',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'CONTACT US',
            buttonLink: 'tel: 9090 33 5151',
            image: 'assets/image/home-01.png'
        },
        {
            img:'assets/images/logo.png',
            subTitle: '',
            title: 'Home Solutions',
            paragraph: "<p>Home and All offers a range of high-quality home solutions, including international and standard kitchens, wardrobes, and space-saving solutions.</p> <br>",
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'CONTACT US',
            buttonLink: 'tel: 9090 33 5151',
            image: 'assets/image/home-02.png'
        },
        {
            img:'assets/images/logo.png',
            subTitle: '',
            title: 'Commercial Solutions',
            paragraph: "<p>For businesses looking to furnish their offices, institutions or hospitality spaces, Home and All offers a variety of including office and institutional, hospitality furniture, workstations, office cubicles,& record storage.</p>",
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'CONTACT US',
            buttonLink: 'tel: 9090 33 5151',
            image: 'assets/image/home-03.png'
        }
    ]
    bannerSocialLinks = [
        {
            icon: 'bx bxl-facebook',
            link: 'https://www.facebook.com/profile.php?id=100094208539555'
        },
        {
            icon: 'bx bxl-youtube',
            link: '#'
        },
        {
            icon: 'bx bxl-linkedin',
            link: 'https://www.linkedin.com/company/homeandall/'
        },
        {
            icon: 'bx bxl-instagram',
            link: 'https://instagram.com/home_and_all1?igshid=MzNlNGNkZWQ4Mg=='
        }
    ]
    bannerContactInfo = [
        {
            number: '+91 9090 33 5151',
            email: 'homeandall.marketing@gmail.com'
        }
    ]

    homeSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		margin: 5,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}