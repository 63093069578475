<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/icons/checkout.jpg);">
    <h1 style="margin-top: 25px">{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<!-- Start Checkout Area -->
<section class="checkout-area ptb-100">
    <div class="container">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>

                        <div class="row">
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label >First Name <span class="required">*</span></label>
                                    <input [(ngModel)]="order.first_name" name="first_name" formControlName="first_name" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label required>Last Name <span class="required">*</span></label>
                                    <input [(ngModel)]="order.last_name" name="last_name" formControlName="last_name" (change)="fun()" type="text" class="form-control">
                                </div>
                            </div>
                            <div>
                                <h3 class="title">Billing Address</h3>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.Address" name="Address" formControlName="Address" class="form-control">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.city" name="city" formControlName="city" class="form-control">
                                </div>
                            </div>

                           

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.state" name="state" formControlName="state" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="number" [(ngModel)]="order.pincode" name="pincode" formControlName="pincode" class="form-control">
                                </div>
                            </div>
                        
                            <div>
                                <h3 class="title">Delivery Address</h3>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.deliveryAddress" name="deliveryAddress" formControlName="deliveryAddress" class="form-control">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.deliverycity" name="deliverycity" formControlName="deliverycity" class="form-control">
                                </div>
                            </div>

                           

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State <span class="required">*</span></label>
                                    <input type="text" [(ngModel)]="order.deliverystate" name="deliverystate" formControlName="deliverystate" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="number" [(ngModel)]="order.deliverypincode" name="deliverypincode" formControlName="deliverypincode" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" [(ngModel)]="order.email_address" name="email_address" formControlName="email_address" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>GSTIN</label>
                                    <input type="text" [(ngModel)]="order.GSTIN" name="GSTIN" formControlName="GSTIN" class="form-control">
                                </div>
                            </div>

                           

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="number" [(ngModel)]="order.mobile_no" name="mobile_no" formControlName="mobile_no"class="form-control">
                                </div>
                            </div>

                            

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea [(ngModel)]="order.massage" name="massage" formControlName="massage" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="payment-box">
                                <div class="payment-method">
                                    
                                    <p>
                                        <!-- <input type="radio" id="cash-on-delivery" name="radio-group">
                                        <label for="cash-on-delivery">Cash on Delivery</label> -->
                                    </p>
                                </div>
                                <button type="submit" class="default-btn"  (click)="place_order()"><i class='bx bx-paper-plane'></i> PLACE ORDER</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr *ngFor="let x of order.list">
                                        <td class="product-name"><a routerLink="/simple-product">{{x.pName}}</a></td>
                                        <td class="product-total">
                                            <span class="subtotal-amount"><img src="assets/icons/rupee.png" style="height: 10px;">{{x.pOffer_prize*x.qty}}/-</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="order-subtotal"><span>Cart Subtotal</span></td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount"><img src="assets/icons/rupee.png" style="height: 10px;">{{subtotal}}/-</span>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="order-shipping"><span>Shipping</span></td>
                                        <td class="shipping-price">
                                            <span><img src="assets/icons/rupee.png" style="height: 10px;">{{shipping}}/-</span>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td class="total-price"><span>Order Total</span></td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount"><img src="assets/icons/rupee.png" style="height: 10px;">{{subtotal+shipping}}/-</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                
                                <p>
                                    <!-- <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Cash on Delivery</label> -->
                                </p>
                            </div>
                            <button type="submit" class="default-btn"  (click)="place_order()"><i class='bx bx-paper-plane'></i> PLACE ORDER</button>
                        </div>
                    </div>
                    <h6 style="margin-top: 10%;"><b> Note: </b>*Delivery period subject to after payment confirmation.</h6>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- End Checkout Area -->