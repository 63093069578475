import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-shop-full-width-page-one',
    templateUrl: './shop-full-width-page-one.component.html',
    styleUrls: ['./shop-full-width-page-one.component.scss']
})
export class ShopFullWidthPageOneComponent implements OnInit {
    product: any=[];
    id: any;
    offset= 0;
    catid: any;
    category: any;
    productlist: any;
    search= "";
    toatlProduct:any;
    list=[];

    constructor( public web:WebService) { 

        var obj=localStorage.getItem("cart");
      
        console.log(obj)

        if(!obj)
        {
            this.list=[];
        }
        else
         this.list=JSON.parse(obj);
        
        console.log(this.list)

        this.web.getData({},"?action=getproduct_web&offset="+this.offset+"&search="+this.search).then(result=>{
            var res:any=result; 
           this.product=res.records
           this.toatlProduct=this.product.length;
           console.log(this.product);
           console.log(this.toatlProduct)
           this.web.qty=res.qty;
        });
        this.web.getData({},"?action=getcountproduct").then(result=>{
            var res:any=result; 
           this.productlist=res.records[0]
           console.log(this.productlist);
        });
        // this.web.getData({},"?action=getCategory").then(result=>{
        //     var res:any=result; 
        //    this.category=res.records
        //    console.log(this.category);
        // });
        
    }
    addcart(a){
        this.list.push(a)
        localStorage.setItem('cart',JSON.stringify(this.list))
        console.log(this.list)
        alert('added successfully')
      }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Shop Full Width',
           
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/products/products4.jpg',
            title: 'Ergonomic Desk Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products5.jpg',
            title: 'Office Desk Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products9.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products10.jpg',
            title: 'Wood Patio Chair',
            newPrice: '$226.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products11.jpg',
            title: 'Brown Vinyl Padded',
            newPrice: '$107.99',
            oldPrice: '$140.99',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products12.jpg',
            title: 'Antique Walnut',
            newPrice: '$98.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        }
    ]

    // For Pagination
    shopFullWidth: number = 1;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        {
            name: "Default",
        },
        {
            name: "Popularity",
        },
        {
            name: "Latest",
        },
        {
            name: "Price: low to high",
        },
        {
            name: "Price: high to low",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}