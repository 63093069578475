<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Customizable: Workstations and office cubicles can be designed and customized to fit the specific needs of the office space and the employees.</li>
                    <li>Space-saving: These solutions are designed to save space while maximizing efficiency and productivity.</li>
                    <li>Modular: The modular design allows for easy reconfiguration and expansion as the business grows and evolves.</li>
                    <li>Ergonomic: The design focuses on ergonomics to ensure a comfortable and healthy workspace for employees.</li>
                    <li>Soundproofing: These solutions can be soundproofed to reduce noise levels and improve concentration.</li>
                </ol>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Improved productivity: A well-designed workstation can improve employee productivity by providing them with a comfortable and efficient workspace.</li>
                    <li>Cost-effective: Modular solutions are cost-effective compared to traditional built-in solutions as they can be easily reconfigured or expanded with minimal cost.</li>  
                    <li>Flexibility: Modular solutions provide flexibility in design and can be easily reconfigured to meet the changing needs of the office space and employees.</li>
                    <li>Improved employee satisfaction: A comfortable and ergonomic workspace can lead to improved employee satisfaction, which can lead to increased employee retention and loyalty.</li>
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The pricing for workstations and office cubicles can vary depending on the materials used, customization options, and the size of the office space. On average, the pricing for a basic workstation can range from INR 15,000 to INR 50,000, while a fully customized workstation can cost upwards of INR 1,00,000. Office cubicles can cost anywhere from INR 30,000 to INR 1,00,000, depending on the same factors mentioned above.</p>
    </div>
</section>


<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                            
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>