import { Component } from '@angular/core';

@Component({
  selector: 'app-gallery-page-one',
  templateUrl: './gallery-page-one.component.html',
  styleUrls: ['./gallery-page-one.component.scss']
})
export class GalleryPageOneComponent {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitle = [
      {
          bgImage: 'assets/img/page-title-bg.jpg',
          title: 'Gallery'
      }
  ]
  
  singleKitchenItem = [

      {
          img: 'assets/image/kitchen-1.jpg'
      },
      {
          img: 'assets/img/gallery/gallery2.jpg'
      },
      {
          img: 'assets/img/gallery/gallery3.jpg'
      },
      {
          img: 'assets/img/gallery/gallery4.jpg'
      },
      {
          img: 'assets/img/gallery/gallery5.jpg'
      },
      {
          img: 'assets/img/gallery/gallery6.jpg'
      },
      {
          img: 'assets/img/gallery/gallery7.jpg'
      },
      {
          img: 'assets/img/gallery/gallery8.jpg'
      },
      {
          img: 'assets/img/gallery/gallery9.jpg'
      }
  ]
  

}
