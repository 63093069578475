import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-trending-products',
    templateUrl: './trending-products.component.html',
    styleUrls: ['./trending-products.component.scss']
})
export class TrendingProductsComponent implements OnInit {
    product: any;
    offset= 0;
    search= "";
    

    constructor(public web:WebService) { 
        this.web.getData({},"?action=getproduct_web&offset="+this.offset+"&search="+this.search).then(result=>{
            var res:any=result; 
           this.product=res.records
           console.log(this.product);
           
        });
    }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Our Office solution",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/image/office-01.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/image/office-02.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/image/office-03.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/image/office-04.png',
            title: '',
            newPrice: '',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        }
    ]


    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}