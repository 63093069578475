import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-official',
  templateUrl: './official.component.html',
  styleUrls: ['./official.component.scss']
})
export class OfficialComponent {
  constructor() { }

  ngOnInit(): void {
  }

  roomsContent = [
      {
        bgImage: 'assets/image/office-bg.png',
        title: 'Office Solutions',
          paragraph: 'Office Furniture refer to a range of furniture products designed for use in commercial spaces such as offices, schools, government buildings, hotels, restaurants, and other similar establishments. This category includes furniture pieces such as workstations, office cubicles, seating solutions, tables, and storage systems specifically designed to meet the needs and requirements of these settings.',
          list: [
              {
                  title: 'Opt for light colours'
              },
              {
                  title: 'Think carefully about flooring'
              },
              {
                  title: 'Add a mirror'
              },
              {
                  title: 'Wall features'
              },
              {
                  title: 'Keep the space cosy'
              }
          ],
          buttonIcon: 'bx bx-shopping-bag',
          buttonText: 'CONTACT US',
          buttonLink: 'tel: 9090 33 5151'
      }
  ]

  singleProductsBox = [
      {
        mainImg: 'assets/image/office_01.png',
        title: 'Rivet Farr Lotus',
          newPrice: '$150.00',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/office_02.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/office_03.png',
        title: 'Mesh Computer Desk',
          newPrice: '$223.99',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/office_04.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      }
  ]

  productsSlidesOptions: OwlOptions = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='bx bx-left-arrow-alt'></i>",
    "<i class='bx bx-right-arrow-alt'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    768: {
      items: 2
    },
    992: {
      items: 3
    },
    1200: {
      items: 3
    }
  }
  }
}
