<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/images/ac-1.webp);">
    <h1 style="color: aliceblue;">{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<!-- Start Gallery Area -->
<section class="gallery-area pt-100 pb-70" id="kitchen" style="padding-top: 50px !important;">
    <div class="container">
        <div class="row">
            <!-- <h3 style="text-align: center;">Home Solutions</h3> -->
            <h3 style="text-align: center; margin-bottom: 5px;">Home Solutions</h3>
            <div class="col-lg-6 " >
                    <div class="card" style="border-color: black; background-color:#f3f2bf;">
                        <h5 style="text-align: center; margin-bottom: 5px;">Kitchen Solutions</h5>

                    <div class="row" style="margin: 5px;">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of singleKitchenItem;">
                            <div class="single-gallery-item">
                                <a class="popup-btn" href="{{Image.img}}">
                                    <img [src]="Image.img" alt="image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
            <div class="col-lg-6 ">
                    <div class="card" style="border-color: black;  background-color:#f3f2bf;">
                    <h5 style="text-align: center; margin-bottom: 5px;">Wardrobe Solutions</h5>
                    <div class="row" style="margin: 5px;">
                        <div class="col-lg-4 col-md-4 col-sm-4" *ngFor="let Image of WardrobeItem;">
                            
                            <div class="single-gallery-item">
                                <a class="popup-btn" href="{{Image.img}}">
                                    <img [src]="Image.img" alt="image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 3%;">
 
         
            <div class="col-lg-6 ">
                <div class="card" style="border-color: black;  background-color:#f3f2bf;">
                <h5 style="text-align: center; margin-bottom: 5px;">Bedroom Solutions</h5>
                <div class="row" style="margin: 5px;">
                    <div class="col-lg-4 col-md-4 col-sm-4" *ngFor="let Image of BedItem">
                        
                        <div class="single-gallery-item">
                            <a class="popup-btn" href="{{Image.img}}">
                                <img [src]="Image.img" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>
<section class="gallery-area pt-100 pb-70" id="kitchen" style="padding-top: 50px !important;">
    <div class="container">
        <div class="row">
            <!-- <h3 style="text-align: center;">Home Solutions</h3> -->
            <h3 style="text-align: center; margin-bottom: 5px;">Commercial Solutions</h3>
            <div class="col-lg-6 " >
                    <div class="card" style="border-color: black; background-color:#f3f2bf;">
                        <!-- <h5 style="text-align: center; margin-bottom: 5px;"></h5> -->

                    <div class="row" style="margin: 5px;">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of singlecommercialItem;">
                            <div class="single-gallery-item">
                                <a class="popup-btn" href="{{Image.img}}">
                                    <img [src]="Image.img" alt="image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 ">
                <div class="card" style="border-color: black;  background-color:#f3f2bf;">
                <div class="row" style="margin: 5px;">
                    <div class="col-lg-4 col-md-4 col-sm-4" *ngFor="let Image of officelItem;">
                        
                        <div class="single-gallery-item">
                            <a class="popup-btn" href="{{Image.img}}">
                                <img [src]="Image.img" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->
