<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/images/ac-1.webp);">
    <h1>{{Content.title}}</h1>
   
</div>
<!-- End Page Title Area -->

<!-- Start Gallery Area -->
<section class="gallery-area pt-100 pb-70" id="kitchen">
    <div class="container">
        <h1>Our Kitchen Solution</h1>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of singleKitchenItem;">
                
                <div class="single-gallery-item">
                    <a class="popup-btn" href="{{Image.img}}">
                        <img [src]="Image.img" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->
