import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from 'src/app/web.service';

@Component({
  selector: 'app-home-demo-one',
  templateUrl: './home-demo-one.component.html',
  styleUrls: ['./home-demo-one.component.scss']
})
export class HomeDemoOneComponent implements OnInit {

  category: any;
  subcategory: any;
  id: any;
  qty=0;
  constructor(public web:WebService,public router:Router) {
      this.web.getData({},"?action=getcategory").then(result=>{
          var res:any=result; 
         this.category=res.records
         console.log(this.category);
      });
      
   }
  ngOnInit(): void {
  }

}
