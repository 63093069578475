<app-navbar-style-one></app-navbar-style-one>

<app-homeone-banner></app-homeone-banner>

<!-- Start Categories Area -->
<section class="hide">
    <div >
        <div class="row">
            <div style="text-align: center;
            font-size: 25px;
            color: black;"><B>SHOP NOW</B> </div>
            <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <div style="text-align: center;
            font-size: 20px;
            font-weight: bold;background-color: #a6a553;
            border-radius: 10px;
            padding: 10px;
            margin: 10px;"><a href="#/shop-left-sidebar-1/-1" style="color: #ffff;">Search For All Products</a> </div>
            </div>
        </div>
        <div class="row">
            <div  class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"  *ngFor="let x of category;let i=index">
                    <div class="card" style="background-color: #a6a553;
                    margin: 10px;
                    text-align: center;padding-top: 15px; padding-bottom: 15px; min-height: 70px; box-shadow: 0px 0px 5px 2px #94934b;
                    padding: 15px">

                        <a href="#/shop-left-sidebar-1/{{x.iId}}"  > 
                        <div  >
                            <!-- <img src="assets/image/home-11.png" alt="image"> -->
                            <div >
                                <h3 style="font-size: 15px;color: #fff;">{{x.sName}}</h3>
                            </div>
                            <!-- <a routerLink="/{{Content.link}}" class="link-btn"></a> -->
                        </div>
                        
                        </a>
                    </div>
             </div>           
           
        </div>
    </div>
</section>
<!-- End Categories Area -->

<section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section>


<app-homeone-categories></app-homeone-categories>

<section class="products-area pb-100">
    <app-trending-products></app-trending-products>
</section>

<app-facility-style-one></app-facility-style-one>

<section class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</section>

<app-testimonials></app-testimonials>

<app-blog></app-blog>

<app-partner></app-partner>