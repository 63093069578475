import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-homeone-categories',
    templateUrl: './homeone-categories.component.html',
    styleUrls: ['./homeone-categories.component.scss']
})
export class HomeoneCategoriesComponent implements OnInit {

    constructor(public router:Router) { }

    ngOnInit(): void {
    }
    getCategory(){
        this.router.navigateByUrl('/shop-left-sidebar-1/16')
    }
    // singleCategoriesBigBox = [
    //     {
    //         img: 'assets/image/img-1.jpg',
    //         title: 'Home Solutions',
    //         buttonText: 'Shop Now',
    //         link: 'shop-full-width-1'
    //     },
    //     {
    //         img: 'assets/image/img-1.jpg',
    //         title: 'Home Solutions',
    //         buttonText: 'Shop Now',
    //         link: 'shop-full-width-1'
    //     }
    // ]
    singleCategoriesBox = [
        {
            img: 'assets/image/home-11.png',
            title: 'Home Solutions',
            buttonText: 'Shop Now',
            link: ''
        },
        {
            img: 'assets/image/home-12.png',
            title: 'Kitchen Solutions',
            buttonText: 'Shop Now',
            link: ''
        }
    ]
    singleCategorieBox = [
        {
            img: 'assets/image/img-2.jpg',
            title: 'Commercial Solutions',
            buttonText: 'Shop Now',
            link: ''
        },
        {
            img: 'assets/image/home-13.png',
            title: 'Components',
            buttonText: 'Shop Now',
            link: '/shop-left-sidebar-1/16'
        }
    ]

}