<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ul>
                    <li>Furniture for hotels, restaurants, cafes, bars, and other hospitality businesses that require stylish, comfortable, and functional seating, tables, booths, sofas, stools, etc. that can enhance the customer experience and reflect the brand image.</li>
                   
                </ul>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ul>
                    <li>Hospitality furniture: can enhance the ambiance, comfort, and appeal of hospitality venues by adding unique and attractive furniture that matches the theme, mood, and functionality of the space. Can also increase customer satisfaction, loyalty, and referrals by providing comfortable, memorable, and Instagrammable experiences.</li>
                   
                  
                </ul>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>Hospitality furniture: can be relatively expensive due to the emphasis on design, comfort, and durability. For example, a restaurant chair can cost around Rs. 5,000-10,000, while a luxury hotel bed can cost over Rs. 1 lakh. A small cafe or bar may require an investment of Rs. 5-10 lakhs, while a large hotel or resort may require an investment of several crores.</p>
    </div>
</section>


<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a  class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                            
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>