<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>

<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Various sizes and configurations available to fit different spaces and storage needs</li>
                    <li>Durable materials and construction for long-term use and protection of documents</li>
                    <li>Easy accessibility and retrieval of documents through labeling and organization systems.</li>
                    <li>Security options such as locks and fireproofing for added protection of confidential documents.</li>
                   
                </ol>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Increased efficiency and productivity by streamlining document storage and retrieval processes</li>
                    <li>Reduced clutter and improved organization leading to a cleaner and more professional workspace</li>  
                    <li>Protection of important and confidential documents from damage, loss, or theft</li>
                    <li>Compliance with legal and regulatory requirements for document storage and retention</li>
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The cost of commercial solutions for record storage can vary depending on the size, materials, and additional features. A basic record storage unit can start at around Rs. 10,000 and go up to Rs. 50,000 or more for larger, high-security units. It is recommended to consult with a professional provider to determine the best solution for your business's specific needs and budget.</p>
    </div>
</section>

<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>



	
