<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Multiple storage options including shelves, hanging rods, and drawers.</li>
                    <li>Customizable designs to fit any space and meet individual needs.</li>
                    <li>Durable materials such as high-quality plywood, laminates, and hardware.</li>
                    <li>Stylish finishes and colors to complement any home decor.</li>

                </ol>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Maximizes storage space and keeps belongings organized and easily accessible.</li>
                    <li>Helps reduce clutter and improve the overall appearance of your home.</li>  
                    <li>Customizable designs to meet individual needs and preferences.</li>
                    <li>Durable materials ensure long-lasting use and easy maintenance.</li>
                  
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The price of wardrobes and space-saving solutions varies depending on the design, materials used, and size of the space. On average, a basic wardrobe can cost anywhere from Rs. 20,000 to Rs. 50,000, while a fully customized wardrobe with premium materials and features can cost upwards of Rs. 1,00,000. Space-saving solutions such as pull-out drawers, wall-mounted shelves, and corner units can range from Rs. 5,000 to Rs. 30,000, depending on the complexity of the design and materials used.</p>
    </div>
</section>
<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                            
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>