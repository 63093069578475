import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-standard-kitchen',
  templateUrl: './standard-kitchen.component.html',
  styleUrls: ['./standard-kitchen.component.scss']
})
export class StandardKitchenComponent {
  constructor() { }

  ngOnInit(): void {
  }

  roomsContent = [
      {
          bgImage: 'assets/image/std-k02.png',
          title: 'Standard Kitchen',
          paragraph: 'A standard modular kitchen is a pre-designed kitchen layout that includes cabinets, countertops, and other modular components that can be arranged to fit the dimensions and requirements of a given space. Here are some of the features, benefits, and approximate pricing of a standard modular kitchen:',
          list: [
              {
                  title: 'Opt for light colours'
              },
              {
                  title: 'Think carefully about flooring'
              },
              {
                  title: 'Add a mirror'
              },
              {
                  title: 'Wall features'
              },
              {
                  title: 'Keep the space cosy'
              }
          ],
          buttonIcon: 'bx bx-shopping-bag',
          buttonText: 'CONTACT US',
          buttonLink: 'tel: 9090 33 5151'
      }
  ]

  singleProductsBox = [
      {
          mainImg: 'assets/image/standard_01.png',
          title: 'Rivet Farr Lotus',
          newPrice: '$150.00',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/standard_02.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/standard_03.png',
        title: 'Mesh Computer Desk',
          newPrice: '$223.99',
          oldPrice: '',
          detailsLink: 'simple-product'
      },
      {
        mainImg: 'assets/image/standard_04.png',
        title: 'Modern Leather Soft',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          detailsLink: 'simple-product'
      }
  ]

  productsSlidesOptions: OwlOptions = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='bx bx-left-arrow-alt'></i>",
    "<i class='bx bx-right-arrow-alt'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    768: {
      items: 2
    },
    992: {
      items: 3
    },
    1200: {
      items: 3
    }
  }
  }
}

