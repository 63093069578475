import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'About Us'
        }
    ]

    aboutContent = [
        {
           
            subTitle: `ABOUT US`,
            title: `We've Been Thriving in 37 Years In This Area`,
            paragraph: `With a strong focus on customer satisfaction, Home and All is committed to providing elegant and functional solutions that cater to a diverse range of interior requirements. Whether it's for a home or an office, Home and All's superior products and services make it the go-to choice for all interior furnishing needs.`,
            content: [
                
                {
                    title: `Our Mission`,
                    paragraph: `''We provide a wide range of finest home & office furniture components & solutions through our franchisee network with well-trained sales team and design assistance to serve our associates & end users to make elegant and efficient sourcing of their interior needs."`
                },
                // {
                //     title: `Our Vision`,
                //     paragraph: `Advance manufacturing facility with 3000 board per month processing capacity & 40+ franchisees in Metro & Major Districts of West & 
                //     South India With Target Revenue of 100 Cr till year 2027`
                // },
                // {
                //     title: `Our Values`,
                //     paragraph: `Always Team Customer First Always Apprise thinking Innovative Ideas Always Efficient Efficiency in every action Respecting Time`
                // }
            ]
        }
    ]
    aboutImage = [
        {
            img: 'assets/img/about-img1.jpg'
        },
        {
            img: 'assets/img/about-img2.jpg'
        }
    ]

}