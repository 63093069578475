<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{bgClass}}">
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>About Us </h3>
                        With a strong focus on customer satisfaction, Home and All is committed to providing elegant and functional solutions that cater to a diverse range of interior requirements.
                        Whether it's for a home or an office, Home and All's superior products and services make it the go-to choice for all interior furnishing needs.

                    <!-- <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p> -->
                    
                </div>
            </div>

            
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Head Office</h3>
                    <ul class="footer-contact-info">
                        <li><b style="color: #0e5e5d;">HOME & ALL</b><br> Rohan Madhuban II, D-304, <br> PVPIT College <br>
                            Bavdhan Khurd, PUNE, <br> MH 411021
                            </li>
                       
                        
                    </ul>
                </div>
            </div>
           
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="footer-contact-info">
                        <li>Email: <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com</a></li>
                        <li>Phone: <a href="tel:+44587154756">(+91) 9090 33 5151</a></li>
                        
                    </ul>
                    <b>Working Hours</b>
                    <p>Tuesday - Sunday : 10:00 AM - 06:30 PM</p>
                   
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Home Solutions</h3>
                    <ul class="footer-contact-info">
                        <li><a routerLink="/international-kitchen">International Kitchen</a></li>
                        <li><a routerLink="/standard-kitchen"> Standard Kitchen</a></li>
                        <li><a routerLink="/wardrobes">Wardrobes</a></li>
                        <li><a routerLink="/space-saving">Space Saving Solutions</a></li>
                    </ul>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/profile.php?id=100094208539555" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/homeandall/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://instagram.com/home_and_all1?igshid=MzNlNGNkZWQ4Mg==" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Commercial Solutions</h3>
                    <ul class="footer-contact-info">
                        <li><a routerLink="/official">Office Furniture</a></li>
                        <li><a routerLink="/institutional"> Institutional Furniture</a></li>
                        <li><a routerLink="/Commercial">Hospitality Furniture</a></li>
                        <li><a routerLink="/Workstations">Workstations & Office Cubicles</a></li>
                        <li><a routerLink="/RecordStorage">Record Storage</a></li>
                    </ul>
                </div>
            </div>
           
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Components Solutions</h3>
                    <ul class="footer-contact-info">
                        <li><a routerLink="/profile-shutters" >Profile Shutters & Partitions</a></li>
                        <li><a routerLink="/highlighter">Highlighter Material</a></li>
                        <li><a routerLink="/hardware">Furniture Hardware</a></li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-5 col-sm-5">
                    <p>Developed by <a href="https://mysoftway.com/" target="_blank">https://mysoftway.com/</a></p>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                   Images by <a href="https://www.freepik.com/free-photo/beautiful-green-kitchen-interior-design_38896750.htm#query=international%20kitchen%20furniture&position=13&from_view=search&track=ais">Freepik</a>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5">
                    <ul>
                        <li><a>Privacy Policy</a></li>
                        <li><a>Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/customer-service">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Terms & Conditions</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
            <div class="modal-body">
                <div class="logo">
                    <a href="index.html" class="d-inline-block"><img src="assets/images/logo.png" alt="image" style="height: 150px;"></a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/item-6.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/item-7.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/item-2.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/item-3.webp" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/bg.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/images/abc-3.webp" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+11234567890">+91 99090 33 5151</a>
                        <span>OR</span>
                        <a href="mailto:hello@fiwan.com">homeandall.marketing@gmail.com
                        </a>
                    </h2>
                </div>
                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li><a href="https://www.facebook.com/profile.php?id=100094208539555" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/homeandall/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://instagram.com/home_and_all1?igshid=MzNlNGNkZWQ4Mg==" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->

<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
<!-- <div class="productsFilterModal modal right fade" id="productsFilterModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="bx bx-x"></i> Close</span>
            </button>
            <div class="modal-body">
                <aside class="widget-area p-0">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/shop-left-sidebar-1">Accessories</a><span class="post_count">(5)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Men</a><span class="post_count">(7)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Women</a><span class="post_count">(3)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Sofa</a><span class="post_count">(2)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Uncategorized</a><span class="post_count">(8)</span></li>
                        </ul>
                    </div>
                    <div class="widget widget_colors">
                        <h3 class="widget-title">Filter By Color</h3>
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="gray">Gray</span>
                                    <span>Gray</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="green">Green</span>
                                    <span>Green</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="red">Red</span>
                                    <span>Red</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="black">Black</span>
                                    <span>Black</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="brown">Brown</span>
                                    <span>Brown</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_price_filter">
                        <h3 class="widget-title">Filter by Price</h3>
                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div>
                    <div class="widget widget_top_rated_products">
                        <h3 class="widget-title">Our Best Sellers</h3>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Long Sleeve Leopard</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Causal V-Neck Soft</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star-half'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Yidarton Women's Comfy</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Womens Tops Color</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div> -->
<!-- End Sidebar Modal Area -->