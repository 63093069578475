<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <!-- <p  style="color: #fff;">{{Content.paragraph}}</p> -->
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Comes in different types such as LED lights, spotlights, and strip lights.</li>
                    <li>Can be installed in various areas of the furniture such as under the cabinets or shelves.</li>
                    <li>Energy-efficient and long-lasting.</li>
                    
                    
                </ol>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Illuminates the furniture and adds a stylish touch.</li>
                    <li>	Helps in easy visibility and accessibility of items in the furniture.</li>
                    <li>Reduces energy consumption.</li>
                    
                  
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The price for highlighter material also varies depending on the type and brand. The cost can range from INR 500 to INR 2,000 per piece.</p>
    </div>
</section>



<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

