<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/images/shop.jpg);">
    <h1>Shop</h1>
    
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="fiwan-grid-sorting row align-items-center">
            <div class="col-lg-8 col-md-6 result-count">
                <div class="d-flex align-items-center"  >
                    <a href="#" data-bs-toggle="modal" data-bs-target="#productsFilterModal" class="sidebar-filter"><i class='bx bx-filter'></i> Filter</a>
                    <p>We found <span class="count">{{toatlProduct}}</span> products available for you</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let x of product | paginate: { itemsPerPage: 9, currentPage: shopFullWidth }">
                <div class="single-products-box">
                    <div class="products-image">
                        <a  class="d-block">
                            <img src="http://adiinnova.com/api/upload/{{x.pPicture}}.png" alt="image" style="height: 260PX;">
                        </a>
                        <ul class="products-button">
                            <li><a routerLink="/wishlist"><i class='bx bx-heart'></i></a></li>
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                            <li><a href="#" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                            <li><a ><i class='bx bx-link'></i></a></li>
                        </ul>
                        <a (click)="addcart(x)" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a >{{x.pName}}</a></h3>
                        <div class="price">
                            <span class="new-price"> Rs.{{x.pOffer_prize}}</span>
                            <span class="old-price" *ngIf="x.pMRP">Rs.{{x.pMRP}}</span>
                        </div>
                    </div>
                    <!-- <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                    <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div> -->
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="shopFullWidth = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->