<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/icons/components.jpg);">
    <h1 *ngIf="flag==false">Categories</h1>
    <aside *ngIf="flag==true" class="widget-area left-widget-area" style="width: 60%; margin: auto;">
        <div class="widget widget_search">
            <form class="search-form">
                <label>
                    <input type="text" [(ngModel)]="config.searchk" name="searchk"    class="search-field" placeholder="Enter Some Keyword For Search...">
                </label>
                <button (click)="onclick()" type="submit"><i class="bx bx-search-alt"></i></button>
            </form>
        </div>
    </aside>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section *ngIf="flag==false" class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="text" [(ngModel)]="config.searchk" name="searchk"    class="search-field" placeholder="Search...">
                            </label>
                            <button (click)="onclick()" type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class=unhide>
                        <div class="widget widget_categories" >
                            <h3 class="widget-title">Sub-Categories</h3>
                                <ul *ngFor="let x of subcategory; let i=index">
                                    <li><a (click)="goto(i)" style="cursor: pointer;">{{x.sName}}</a>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <!-- <div class="hide">
                        <!-- <div class="widget widget_categories" style="width: 100%;" >
                            <a data-toggle="collapse" href="#collapse1">Sub-Categories</a> -- >
                            <button class="collapsible" id="cbuttion" (click)="collaps()">Sub-Categories</button>
                            <div class="content">
                                <ul *ngFor="let x of subcategory; let i=index">
                                    <li class="close"><a (click)="goto(i)" style="cursor: pointer;">{{x.sName}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </div> -->
                    <div class="hide">
                        <div class="widget widget_categories" style="width: 100%;" >
                            <label id="cbuttion" class="collapse" for="_1" style="margin-bottom: 10px;padding: 10px;color: white;">SUB-CATEGORIES<img src="assets/icons/plusn.png" style="height: 20px;
                                float: right;"></label>
                            <input id="_1" type="checkbox"> 
                            <div>
                                <ul *ngFor="let x of subcategory; let i=index">
                                    <li class="close"><a (click)="goto(i)" style="cursor: pointer;">{{x.sName}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            
            
            <div class="col-lg-8 col-md-12">
                <h6>{{selectedSubcat}}</h6>
                <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center">
                            <p>We found <span class="count">{{product.length}}</span> products available for you</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box" >
                            <label>Sort By:</label>
                            <select (change)="onChange()" [(ngModel)]="config.sort" name="sort"  style="background-color: #f2f4f5; border: none; height: 30px;">
                                <option value="l">Price: low to high</option>
                                <option value="h">Price: high to low</option>
                                <option value="">Product name</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="row" *ngIf="product.length!=0">
                    <div class="col-lg-3 col-sm-4 col-md-4" *ngFor="let x of product | paginate: { itemsPerPage:16, currentPage: shopLeftSidebar }">
                        <div class="single-products-box" >
                            <div class="products-image">
                                <a  class="d-block">
                                    
                                        <a href="#" (click)="setproduct(x)" data-bs-toggle="modal"  data-bs-target="#productsQuickView"><img onerror="this.onerror=null;this.src='/assets/icons/noimg.png';"  src="https://homeandall.in/api/upload/{{x.pPicture.split('~')[0].trim()}}.png" alt="image" style="height:150px;"></a>
                                    
                                </a>
                                <a href="#" data-bs-toggle="modal"  data-bs-target="#productsQuickView" *ngIf="x.ssize!=null&&x.ssize!='null'" (click)="setproduct(x)" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                                <a *ngIf="x.ssize==null||x.ssize=='null'" (click)="addcart(x)" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                            </div>
                            <div class="products-content">
                                <p style="color: black !important;overflow-wrap: anywhere;"><a >{{x.pName}}</a></p>
                                <div class="price" *ngIf="x.ssize==null||x.ssize=='null'">
                                    <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;"> {{x.pOffer_prize}}/-</span>
                                    <span class="old-price" *ngIf="x.pMRP">{{x.pMRP}}</span>
                                    <span>per {{x.iUnit}}({{x.setof}})</span>
                                </div>
                                <div class="price" *ngIf="x.ssize!=null&&x.ssize!='null'">
                                   
                                    <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;">{{getrange(x)}}</span>
                                    <!-- <span class="old-price" *ngIf="x.pMRP">{{x.pMRP}}</span> -->
                                    <span>per {{x.iUnit}}({{x.setof}})</span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="shopLeftSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div *ngIf="product.length ==0">
                    <img src="assets/icons/notfound.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section  *ngIf="flag==true"  class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center">
                            <p>We found <span class="count">{{product.length}}</span> products available for you</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box" >
                            <label>Sort By:</label>
                            <select (change)="onChange()" [(ngModel)]="config.sort" name="sort"  style="background-color: #f2f4f5; border: none; height: 30px;">
                                <option value="l">Price: low to high</option>
                                <option value="h">Price: high to low</option>
                                <option value="">Product name</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="row" *ngIf="product.length!=0">
                    <div class="col-lg-3 col-sm-4 col-md-4" *ngFor="let x of product | paginate: { itemsPerPage:16, currentPage: shopLeftSidebar }">
                        <div class="single-products-box" >
                            <div class="products-image">
                                <a  class="d-block">
                                    
                                        <a href="#" (click)="setproduct(x)" data-bs-toggle="modal"  data-bs-target="#productsQuickView"><img onerror="this.onerror=null;this.src='/assets/icons/noimg.png';"  src="https://homeandall.in/api/upload/{{x.pPicture.split('~')[0].trim()}}.png" alt="image" style="height:150px;"></a>
                                    
                                </a>
                                <a href="#" data-bs-toggle="modal"  data-bs-target="#productsQuickView" *ngIf="x.ssize!=null&&x.ssize!='null'" (click)="setproduct(x)" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                                <a *ngIf="x.ssize==null||x.ssize=='null'" (click)="addcart(x)" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                            </div>
                            <div class="products-content">
                                <p style="color: black !important;overflow-wrap: anywhere;"><a >{{x.pName}}</a></p>
                                <div class="price" *ngIf="x.ssize==null||x.ssize=='null'">
                                    <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;"> {{x.pOffer_prize}}/-</span>
                                    <span class="old-price" *ngIf="x.pMRP">{{x.pMRP}}</span>
                                    <span>per {{x.iUnit}}({{productDetail.setof}})</span>
                                </div>
                                <div class="price" *ngIf="x.ssize!=null&&x.ssize!='null'">
                                   
                                    <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;">{{getrange(x)}}</span>
                                    <!-- <span class="old-price" *ngIf="x.pMRP">{{x.pMRP}}</span> -->
                                    <span>per {{x.iUnit}}({{productDetail.setof}})</span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="shopLeftSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>

                <div *ngIf="product.length ==0&&loadFlag==true">
                    <img src="assets/icons/loader.gif" alt=""  class="center">
                </div>
                <div *ngIf="product.length ==0&&loadFlag==false">
                    <img src="assets/icons/notfound.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Products Area -->

<div *ngIf="product.length>0" class="modal fade productsQuickView" id="productsQuickView" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content">
            <div class="copy-text" >
                <!-- <input type="text" id="Copy" class="text" value="Good Luck " /> -->
                <!-- <input type="text" id="Copy" class="text" value="Good Luck" /> -->
                <button style="float: right; position: absolute;right: 50px;top: 15px;">
                    <i class="fa fa-clone" (click)="copyCode(productDetail.iId)"></i>
                </button>
            </div> 
            <button style="margin-top: 10px;" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
           
            <div class="row align-items-center" >
                <div class="col-lg-6 col-md-6">
                    <div style="margin-bottom: 100px;">
                    <span  style="margin-bottom: 10px;padding: 10px;" *ngFor="let k of productDetail.pPicture.split('~')">
                        <img (click)="setimg(k)" onerror="this.onerror=null;this.src='/assets/icons/noimg.png';" src="https://homeandall.in/api/upload/{{k.trim()}}.png"  alt="image" style="height: 50px; border: thin solid gray;">
                    </span>
                    </div>
                    <div >
                        <div class="products-image ">
                             <pinch-zoom double-tap="true" wheel="true" wheelZoomFactor="0.5" 
                                listeners="auto"  overflow="hidden" style="background-color: #f2f4f5 !important;">
                                <img onerror="this.onerror=null;this.src='/assets/icons/noimg.png';" id="myimage" src="https://homeandall.in/api/upload/{{selectedimg.trim()}}.png" alt="image">
                            </pinch-zoom>
                        </div>
                    </div>
               

                </div>
                <div class="col-lg-6 col-md-6" style="margin-top: 5%;">
                    <div class="products-content">
                        <h3 style="overflow-wrap: anywhere;">{{productDetail.pName}}</h3>
                        <small>{{selectedSubcat}}</small>
                        <div class="price" *ngIf="sizeFlag==false">
                            <span class="new-price"><img src="assets/icons/rupee.png" style="height: 10px;"> {{productDetail.pOffer_prize}}/-</span>&nbsp;
                            <span class="old-price" *ngtail.pMRP>{{productDetail.pMRP}}</span>&nbsp;&nbsp;
                            <span> per {{productDetail.iUnit}}({{productDetail.setof}})</span>
                        </div>
                        <div *ngIf="sizeFlag==true">
                            <table>
                                <tr >
                                    <th>Size</th>
                                    <th>MRP</th>
                                    <th>Offer-Price</th>
                                </tr>
                                <tr *ngFor="let x of sizeArray,let i=index" >
                                    <td>{{x.ssize}}</td>
                                    <td style="width: 100px;"><img src="assets/icons/rupee.png" style="height: 10px;"> {{x.pMRP}}/-</td>
                                    <td style="width: 100px;"><img src="assets/icons/rupee.png" style="height: 10px;">{{x.pOffer_prize}}/-</td>
                                    
                                    <!-- <td><button (click)="addSize(i)" type="submit"style="    border: none;
                                        position: relative;
                                        display: inline-block;
                                        color: var(--whiteColor);
                                        box-shadow: unset !important;
                                        transition: var(--transition);
                                        background-color: var(--mainColor);
                                        font-size: xx-small;"> ADD TO CART</button></td> -->

                                       
                                </tr>
                                
                            </table>
                        </div>
                        <label><b>Description</b></label><br>
                        <span>{{productDetail.pDiscription}}</span><br>
                        <span><b> Dispatch:</b></span> {{productDetail.iDeliverydays}} <br>
                        *After payment received in our Bank Account.

                        <label> <b>Specification:</b></label>
                        <p> {{productDetail.pProduct_specification}}</p>
                       
                        <ul class="products-info">
                           
                            <label ><b> Notes:</b></label>
                            <li><span>{{productDetail.pProduct_feature}}</span></li>
                            
                        </ul>
                        <div class="products-add-to-cart">
                            <div class="row">
                                <div class="col-lg-6 col-md-3 col-sm-2 col-xs-2">
                                    <div class="input-counter" *ngIf="sizeFlag==true">
                                        <!-- <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                        <input type="text" value="1" min="1">
                                        <span class="plus-btn"><i class='bx bx-plus'></i></span> -->
                                        <select [(ngModel)]="sizePrice" style="padding: 15px 15px 12px 10px;
                                        color: white;
                                        background-color: var(--mainColor);
                                        font-weight: 600;
                                        border: none;" (change)="imgSize(sizePrice)">
                                            <option *ngFor="let x of sizeArray,let i=index" value="{{i}}">
                                                {{x.ssize}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-3 col-sm-2 col-xs-2" *ngIf="sizeFlag==true">
                                    <button (click)="addcart(productDetail)" data-bs-dismiss="modal" type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                                </div>
                                <div class="col-lg-12 col-md-12" *ngIf="sizeFlag==false">
                                    <button (click)="addcart(productDetail)" data-bs-dismiss="modal" type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>