<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <!-- <p>{{Content.paragraph}}</p> -->
        <!-- <a href="/gallery/#kitchen" style="float: right;">View all</a> -->
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                <div class="single-products-box">
                    <div class="products-image">
                            <img [src]="Content.mainImg" alt="image">
                       
                    </div>
                    <div class="products-content" *ngFor="let x of product;">
                        <h3><a routerLink="/{{Content.detailsLink}}">{{x.pProduct_feature}}</a></h3>
                        <div class="price">
                            <span class="new-price">{{x.pOffer_prize}}</span>
                            <span class="old-price" *ngIf="Content.oldPrice">{{x.pMRP}}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>