<!-- Start Navbar Area -->

<div class="navbar-area p-absolute" >
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a href="#/"><img src="assets/images/logo-01.png" alt="logo" style="height: 85px;"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="#/"><img src="assets/images/logo.png" alt="logo" style="height: 50px;"></a>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="#/" class="dropdown nav-link">Home</a>
                        </li>
                        <li class="nav-item"><a href="#/about" class="nav-link" routerLinkActive="active">About us</a>
                        </li>
                        <li class="nav-item"><a  class="dropdown-toggle nav-link" >Our Solutions</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a  class="nav-link" id="HomeSolution">Home Solutions <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="#/international-kitchen" class="nav-link" routerLinkActive="active">International Kitchen</a></li>
                                        <li class="nav-item"><a href="#/standard-kitchen" class="nav-link" routerLinkActive="active">Standard Kitchen</a></li>
                                        <li class="nav-item"><a href="#/wardrobes" class="nav-link" routerLinkActive="active">Wardrobes</a></li>
                                        <li class="nav-item"><a href="#/space-saving" class="nav-link" routerLinkActive="active">Space Saving Solutions</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a  class="nav-link" id="CommercialSolution">Commercial Solutions<i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="#/official" class="nav-link" routerLinkActive="active">Office Furniture</a></li>
                                        <li class="nav-item"><a href="#/institutional" class="nav-link" routerLinkActive="active">Institutional Furniture</a></li>
                                        <li class="nav-item"><a href="#/Commercial" class="nav-link" routerLinkActive="active">Hospitality Furniture</a></li>
                                        <li class="nav-item"><a href="#/Workstations" class="nav-link" routerLinkActive="active">Workstations & Office Cubicles</a></li>
                                        <li class="nav-item"><a href="#/RecordStorage" class="nav-link" routerLinkActive="active">Record Storage</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a  class="nav-link">Components Solutions<i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="#/profile-shutters" class="nav-link" routerLinkActive="active">Profile Shutters & Partitions</a></li>
                                        <li class="nav-item"><a href="#/highlighter" class="nav-link" routerLinkActive="active">Highlighter Material</a></li>
                                        <li class="nav-item"><a href="#/hardware" class="nav-link" routerLinkActive="active">Furniture Hardware</a></li>
                                </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="#/OurProject" class="nav-link" routerLinkActive="active">Our Projects</a>
                        </li>
                        <li class="nav-item"><a href="#/contact" class="nav-link" routerLinkActive="active">Contact us</a></li>
                        <li class="nav-item"><a href="#/blog-grid" class="nav-link" routerLinkActive="active">Blog</a>
                        <li class="nav-item"><a  class="nav-link" routerLinkActive="active">Shop</a>
                            <ul class="dropdown-menu dropdown-scrollbar">
                                <li class="nav-item"><a href="#/shop-left-sidebar-1/{{x.iId}}" class="nav-link"  *ngFor="let x of category;let i=index">{{x.sName}} </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="#/shop-left-sidebar-1/-1"  class="nav-link" routerLinkActive="active">Search</a>
                        <!-- <li class="nav-item"><a  href="#/Product/2850" class="nav-link" routerLinkActive="active">Products</a> -->
                   </ul>
                    <div class="others-option d-flex align-items-center">
                        
                        <div class="option-item">
                            <a href="#/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="option-inner">
                    <div class="others-option d-flex align-items-center justify-content-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>
                        <div class="option-item">
                            <a href="#/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Search Overlay -->
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->