import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    contact: any;
    showForm=false;
    object={
        "name":"",
        "email":"",
        "number":"",
        "message":"",
    }
    object1={
        "fname":"",
        "mobile":"",
        "altnumber":"",
        "email_id":"",
        "state":"",
        "city":"",
        "discussion":"",
        "Location":"",
        "franchisee":"",
        "retail":"",
        "Property":"",
        "Area":"",
        "message":"",
    }
    message: any;

    constructor(public web:WebService) { 

        this.showForm=false;
    }
    send(){
       
       
        this.web.postData({data:this.object},"?action=contactus").then(result=>{
            var res:any=result; 
           this.message=res.records
           console.log(this.message);
           if(res=="1")
        //    console.log(this.contact);
               {
                alert('successfull')
                this.object={
                    "name":"",
                    "email":"",
                    "number":"",
                    "message":"",
                }
               } 
            else
                alert("Msg not sent")    
        });
    

       
    }
    ngOnInit(): void {
    }
    addenquiry(){
        this.showForm=true;
        alert('Your Enquiry has been sent')
    }
    enquiry(){
        this.showForm=true;
    }
    addOrder()
    {

        console.log(this.object)
        this.web.postData(this.object,"?action=add").then(result=>{
            var res:any=result; 
            console.log(res)
            if(res.result=="5")
        //    console.log(this.contact);
               {
                alert('successfull')
                this.object={
                    "name":"",
                    "email":"",
                    "number":"",
                    "message":"",
                }
               } 
            else
                alert("Msg not sent")    
        });
    
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Contact '
        }
    ]

    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var message = form.message;
        console.log(message);
        
    }

}