import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from 'src/app/web.service';

@Component({
    selector: 'app-blog-grid',
    templateUrl: './blog-grid.component.html',
    styleUrls: ['./blog-grid.component.scss']
})
export class BlogGridComponent implements OnInit {
    Blog:any=[]
    constructor(public web:WebService,public router:Router) {
        this.Blog=web.object
        console.log(this.Blog)
     }

   gotodetails(i){
    console.log(i)
    this.router.navigateByUrl("/blog-details/"+i)
    
   }
    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/image/blog-1.png',
            title: 'Blogs'
        }
    ]
    singleBlogPost = [
        {
            img: 'assets/image/blog-01.png',
            tag: 'Furniture',
            title: '"Modular Kitchen Planning: A Comprehensive Guide"',
            paragraph: 'The kitchen is the heart of the home, and modular kitchens have become increasingly popular over the years. ',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-02.png',
            tag: 'Sofa',
            title: '"Crafting Your Dream Interiors: How Home and All Solutions Can Help"',
            paragraph: 'Your home is more than just a living space, it’s a reflection of your personality and style.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-03.png',
            tag: 'Outdoor',
            title: '4 Ways to Create the Ultimate Outdoor Living Space',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-04.png',
            tag: 'Furniture',
            title: 'Decorating Tips for Arranging Living Room Furniture',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-05.png',
            tag: 'Cleaning',
            title: 'Keep Things Organized and Get Ready for Spring Cleaning',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-06.png',
            tag: 'Spotlight',
            title: 'Spotlight on the Natuzzi Editions Furniture Collection',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-07.png',
            tag: 'Furniture',
            title: 'Decorating Tips for Arranging Living Room Furniture',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-08.png',
            tag: 'Cleaning',
            title: 'Keep Things Organized and Get Ready for Spring Cleaning',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-09.png',
            tag: 'Spotlight',
            title: 'Spotlight on the Natuzzi Editions Furniture Collection',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-10.png',
            tag: 'Furniture',
            title: 'Spotlight on Bernhardt Modern American Furniture',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-11.png',
            tag: 'Sofa',
            title: '4 Ways to Decorate your Living Room with a Brown Sofa',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/image/blog-12.png',
            tag: 'Outdoor',
            title: '4 Ways to Create the Ultimate Outdoor Living Space',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        }
    ]

    blogGrid: number = 1;

}