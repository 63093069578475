<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}}); ">
    <div class="container">
        <div class="rooms-content">
            <h2 >{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features </h4>
                <ol>
                    <li>Includes various items such as handles, knobs, hinges, and drawer slides.</li>
                    <li>Made of different materials such as stainless steel, brass, and zinc.</li>
                    <li>Available in various designs and finishes.</li>
                 
                </ol>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ol>
                    <li>Adds a decorative element to the furniture.</li>
                    <li>Facilitates smooth functioning of drawers and cabinets.</li>  
                    <li>Durable and long-lasting.</li>
                    
                  
                </ol>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>The price for furniture hardware varies depending on the type, material, and brand. The cost can range from INR 50 to INR 500 per piece.</p>
    </div>
</section>


<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
