<!-- Start Categories Area -->
<section class="categories-area pt-100 pb-70 ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="single-categories-box" *ngFor="let Content of singleCategoriesBox;">
                    <img [src]="Content.img" alt="image">
                    <div class="content">
                        <h3 style="color: aliceblue;"><a routerLink="/{{Content.link}}" class="link-btn" style="color: aliceblue;">{{Content.title}}</a></h3>
                    </div>
                    <!-- <a routerLink="/{{Content.link}}" class="link-btn"></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-categories-box" *ngFor="let Content of singleCategorieBox;">
                    <img [src]="Content.img" alt="image">
                    <div class="content">
                        <h3 ><a routerLink="/{{Content.link}}" class="link-btn" style="color: aliceblue;">{{Content.title}}</a></h3>
                    </div>
                    <!-- <a routerLink="/{{Content.link}}" class="link-btn"></a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Categories Area -->