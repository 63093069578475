<!-- Start Main Banner Area -->
<div class="home-banner-area">
    <div class="container-fluid">
        <div class="home-slides">
            <owl-carousel-o [options]="homeSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
                    <div class="main-banner-area">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <div class="main-banner-content">
                                    <img  [src]="Content.img" alt="" style=" width: 100px; margin-left: 140px;">
                                    <h6 class="sub-title" >{{Content.subTitle}}</h6>
                                    <h2 style="text-align: center;">{{Content.title}}</h2>
                                   
                                    <div [innerHTML]="Content.paragraph"></div>
                                    <a href="{{Content.buttonLink}}" class="default-btn"><i class="{{Content.buttonIcon}}"></i> {{Content.buttonText}}</a>
                                </div>
                            </div>
        
                           <div class="col-lg-7 col-md-12">
                                <div class="main-banner-image text-center" style="background-image: url({{Content.image}});">
                                    <img [src]="Content.image" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <ul class="banner-social-links">
        <li *ngFor="let Links of bannerSocialLinks;"><a href="{{Links.link}}" target="_blank"><i class="{{Links.icon}}"></i></a></li>
    </ul>
    <div class="banner-contact-info">
        <ul *ngFor="let Info of bannerContactInfo;">
            <li><a href="tel:{{Info.number}}"><i class='bx bx-phone-call'></i> {{Info.number}}</a></li>
            <li><a href="mailto:{{Info.email}}"><i class='bx bx-envelope'></i> {{Info.email}}</a></li>
        </ul>
    </div>
</div>
<!-- End Main Banner Area -->