import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/web.service';
import { CartPageComponent } from '../cart-page/cart-page.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-checkout-page',
    templateUrl: './checkout-page.component.html',
   styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
    productlist: any;
    

    order ={
        first_name:"",
        last_name:"",
        company_name:"",
        Address:"",
        city:"",
        state:"",
        pincode:"",
        GSTIN:"",
        email_address:"",
        mobile_no:"",
        country:"",
        cart_subtotal:"",
        shipping:"0",
        order_total:"",
        order_id:"",
        massage:"",
        list:[],
        deliveryAddress:"",
        deliverycity:"",
        deliverystate:"",
        deliverypincode:"",

    }
    form = new FormGroup({
        first_name :new FormControl('',[Validators.required]),
        last_name :new FormControl('',[Validators.required]),
        Address :new FormControl('',[Validators.required]),
        city :new FormControl('',[Validators.required]),
        state :new FormControl('',[Validators.required]),
        pincode :new FormControl('',[Validators.required]),
        email_address :new FormControl('',[]),
        mobile_no :new FormControl('',[Validators.required]),
        massage :new FormControl('',[]),
        GSTIN:new FormControl('',[]),
        deliveryAddress:new FormControl('',[Validators.required]),
        deliverycity:new FormControl('',[Validators.required]),
        deliverystate:new FormControl('',[Validators.required]),
        deliverypincode:new FormControl('',[Validators.required]),
    })
    addorder: any;
    subtotal=0
    shipping=0
    order_total=0
    constructor(public web:WebService)
     { 
        var obj=localStorage.getItem("cart");
      
        console.log(obj)

        if(!obj)
        {
            this.order.list=[];   
        }
        else
        this.order.list=JSON.parse(obj);
        console.log(this.order.list)
        for(let i=0;i<this.order.list.length;i++)
         {
            this.subtotal=this.subtotal+(parseFloat(this.order.list[i].pOffer_prize))*(this.order.list[i].qty)
            this.order.cart_subtotal=JSON.stringify(this.subtotal)
            this.order_total=this.subtotal+parseInt(this.order.shipping)
            this.order.order_total = JSON.stringify(this.order_total)

            console.log(this.order.list[i].pOffer_prize)
            console.log(this.order.cart_subtotal)

         }
        
       
    }
    place_order(){
       
        console.log(this.order)
        this.web.postData({data:this.order},"?action=addorder").then(result=>{
            var res:any=result; 
        //    this.addorder=res.records[0]
        //    console.log(this.addorder);
           if(res.result==1)
             {
               alert(' order placed successfully.We will get back to you soon.')
               localStorage.setItem('cart',JSON.stringify([]));
               this.order ={
                first_name:"",
                last_name:"",
                company_name:"",
                Address:"",
                city:"",
                state:"",
                pincode:"",
                GSTIN:"",
                email_address:"",
                mobile_no:"",
                country:"",
                cart_subtotal:"",
                shipping:"0",
                order_total:"",
                order_id:"",
                massage:"",
                list:[],
                deliveryAddress:"",
                deliverycity:"",
                deliverystate:"",
                deliverypincode:"",
                 
            }
               window.location.href="/"

            }
            else{
                alert('something went wrong please insert valid data')
            }

        });
        
        
    //  window.location.href="/"
        
    }
    fun(){
        console.log(this.order);
    }
    ngOnInit(): void {
       
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Checkout'
        }
    ]

    // Country Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}