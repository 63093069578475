
<app-navbar-style-one ></app-navbar-style-one>

<section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="margin-top: 7%; background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content" style="color: black;">
            <h2 style="color: #fff;">{{Content.title}}</h2>
            <p  style="color: #fff;">{{Content.paragraph}}</p>
            <a href="{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section>
<section>
    <div class="container" style="margin-top: 3%;">
        <div class="row">
            <div class="col-md-6" >
                <h4>Features</h4>
                <ul>
                    <li>Office furniture - desks, chairs, tables, storage cabinets, bookcases, partitions, etc. with various designs, sizes, materials, and colors to meet different functional and aesthetic requirements of offices.</li>
                </ul>
            </div>
            <div class="col-md-6">
                <h4>Benefits </h4>
                <ul>
                    <li>Office furniture: can improve productivity, organization, and morale of employees by providing comfortable, efficient, and customizable workspaces that suit their tasks, preferences, and health needs. Can also create a professional and welcoming atmosphere for clients and visitors</li>
                </ul>
            </div>
           
        </div>
        <h4>Pricing</h4>
        <p>Office furniture: can range from a few thousand rupees to several lakhs depending on the quality, quantity, and customization of the items. For example, a basic office chair can cost around Rs. 3,000-5,000, while a high-end executive desk can cost over Rs. 1 lakh. A small office with 5-10 workstations and some storage cabinets may require an investment of Rs. 1-2 lakhs, while a large corporate office with hundreds of employees and multiple zones may require an investment of several crores.</p>
    </div>
</section>
<section class="products-area ptb-100 bg-f5f5f5" style="color: aliceblue;">
    <div class="container">
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a class="d-block">
                                <img [src]="Content.mainImg" alt="image">
                            </a>
                        </div>
                       
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>