import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            //subTitle: "CUSTOMER REVIEWS",
            title: "Some of our valuable customers reviews :"
        }
    ]
    singleTestimonialsItem = [
        {
            feedback: '"I was very impressed with the quality and design of the wardrobes and space saving solutions provided by Home & All. They were exactly what we needed to optimize the space in our home. The team was also very professional and easy to work with."',
            authorImg: 'assets/img/user1.jpg',
            authorName: 'Mr. Sagar Shirdhone, Shedshal',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"Home & All provided us with a beautiful and functional modular kitchen that exceeded our expectations. The attention to detail and the quality of materials used were top-notch. We highly recommend them."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Satyam Kekle, Ichalkaranji',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"We were looking for a company that could provide us with a turnkey solution for our office furniture needs and Home & All did just that. They provided us with stylish and functional workstations and cubicles that our employees love."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr.  Sanjay Khot, Ichalkaranji',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: "Home & All's international kitchen solutions were the perfect fit for our home. The design was modern and elegant and the functionality was exactly what we were looking for. We highly recommend them.",
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Colonel Mandlik, Kolhapur',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"The highlighter material provided by Home & All was exactly what we needed to add a pop of color to our interiors. It was easy to install and added a touch of personality to our space."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Deepak Patil, Jaysingpur',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"The furniture hardware provided by Home & All was of high quality and added the perfect finishing touch to our home interiors. We were very happy with the product and the service provided."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Swapnil Gaurgonda, Pune',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: "Home & All's record storage solutions were exactly what we needed to organize our office. The team provided us with a practical and efficient solution that exceeded our expectations.",
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Cache Technologies, Kolhapur ',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: "We were very impressed with Home & All's attention to detail and design expertise. They were able to provide us with a custom solution for our home that perfectly suited our needs.",
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mrs. Bharati Yendagauder, Shedbal',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"The profile shutters and partitions provided by Home & All were of excellent quality and added a touch of elegance to our interiors. We highly recommend them."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Ajit Madke, Hupri',
            // authorDesignation: 'Web Developer'
        },
        {
            feedback: '"Home & All provided us with a hassle-free experience when it came to installing our home interiors. They were efficient, professional, and the final result was exactly what we wanted."',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mr. Sanjay Chougule, Ichalkaranji',
            // authorDesignation: 'Web Developer'
        }
    ]

    testimonialsSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplay: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}