<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url(assets/image/about-bg.png);">
    <h1 style="color: aliceblue;  margin-right: 50%;">{{Content.title}}</h1>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" *ngFor="let Content of aboutContent;">   
            <div class="row">
               
                <div class="col-lg-7 col-md-7">
                    <h3 class="sub-title"><b>{{Content.subTitle}}</b></h3>
                    <p>{{Content.paragraph}}</p>

                    <div class="content" *ngFor="let Text of Content.content">
                        <h3>{{Text.title}}</h3>
                        <p>{{Text.paragraph}}</p>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5" >
                    <img src="assets/image/about-logo.png" alt="" style="height: 80%; margin-left:82px "> 
                </div>
            </div> 
            
        </div> 
        <div>
            <div style="text-align: center;"> 
                <img src="assets/image/man-01.png" alt="" style="height: 300px;">
            </div>
            <h2 style="text-align: center;">Working Issues</h2>
            <ol style="margin-top: 30px;">
                <div class="row" >
                    <div class="col-md-6">
                        <li>Limited space: Many people face the challenge of limited space in their homes, especially in urban areas. This limits their ability to use conventional furniture and storage solutions</li>
                        <li>Lack of storage: Inadequate storage is a common problem faced by many homeowners. They struggle to keep their homes organized and clutter-free.</li>
                        <li>Inefficient use of space: Many people struggle to utilize the available space in their homes effectively. They may not know how to arrange furniture or use partitions effectively.</li>
                        <li>Poor quality furniture: Customers may have experienced purchasing furniture that is not durable and does not meet their expectations.</li>
                        <li>Hard to find good, trustworthy supplier </li>
                        <li>Live Hardware Experience Display Arrangements</li>
                        <li>Lack of technical expertise</li>
                        <li>Unclarified Cost Structure</li>
                    </div>
                    <div class="col-md-6">
                        <li>Limited design options: Customers may find it difficult to find furniture that matches their design preferences and home decor.</li>
                        <li>Limited access to quality furniture: Customers may not have access to quality furniture and may have to settle for subpar products due to limited availability.</li>
                        <li>Lack of design assistance: Customers may struggle to design their homes effectively, especially when it comes to modular kitchen design and space-saving solutions.</li>
                        <li>Limited customer support: Customers may not receive the necessary customer support when purchasing and installing furniture.</li>
                        <li>No connections with designers/carpenter</li>
                        <li>Unable to supervise far away sites</li>
                        <li>Irresponsibility of Higher expenditure than proposed budget</li>
                        <li>Lack of knowledge & guidance</li>
                    </div>
                </div>
            </ol>
            <div style="text-align: center;">
                <h2 >Are You Facing the Same Issues <span style="color: darkred;">?</span></h2>
                <h4>We are here to help you</h4>
            </div>
        </div>
    </div>
    <div>
        <img src="assets/image/oprah.png" alt="">
    </div>
    
</section>


<app-facility-style-one></app-facility-style-one>